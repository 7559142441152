import querystring from 'query-string';
import api from './api.config';

/**
 * @typedef {Object} Folder
 * @property {string} id                        ID of the folder
 * @property {string} creationDate              Creation date of the folder
 * @property {string} creationUserId            ID of the user who created the folder
 * @property {string} modificationDate          Modification date of the folder
 * @property {string} modificationUserId        ID of the user who modified the folder
 * @property {string} name                      Name of the folder
 * @property {string} context                   Context of the folder
 * @property {string} description               Description of the folder
 * @property {string} parentId                  ID of the parent folder
 */

/**
 * Return the folder resource URL.
 *
 * @param {string} customerCode The customer code
 * @return {string} URL
 */
function foldersBaseUrl(customerCode) {
    return `/timeseries/v2/${customerCode}/folders`;
}

function addQueryParams(url, params) {
    const query = querystring.stringify(params);
    return query ? `${url}?${query}` : url;
}

/**
 * GET all folders
 *
 * @param {Object}   params
 * @param {string}   params.customerCode The customer code
 * @param {string[]} params.fields       Context
 * @param {object}   params.filters      List of filters
 * @returns {Promise<Folder[]>}                   List of folders
 */
export async function getFolders({ customerCode, fields, filters }) {
    try {
        const url = addQueryParams(foldersBaseUrl(customerCode));
        const response = await api.get(url, {
            headers: {
                Accept: 'application/json',
            },
            params: {
                ...(fields && { _fields: fields.join() }),
                ...filters,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * POST a folder
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {string} params.folder       Folder to create
 * @return {Promise<Folder>}                    Created folder
 */
export async function createFolder({ customerCode, folder }) {
    try {
        const response = await api.post(`${foldersBaseUrl(customerCode)}`, folder, {
            headers: {
                Accept: 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * PUT a folder
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {Folder} params.folder       Folder to update
 * @return {Promise<Folder>}                    Updated folder
 */
export async function updateFolder({ customerCode, folder }) {
    try {
        const response = await api.put(`${foldersBaseUrl(customerCode)}/${folder.id}`, folder, {
            headers: {
                Accept: 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * DELETE a folder
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {string} id                  ID of the folder to update
 */
export async function deleteFolder({ customerCode, id }) {
    try {
        await api.delete(`${foldersBaseUrl(customerCode)}/${id}`, {
            headers: {
                Accept: 'application/json',
            },
        });
    } catch (error) {
        throw new Error(error.response);
    }
}

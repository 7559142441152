import api from './api.config';

/**
 * Returns the API keys resource URL.
 *
 * @param {string} customerCode Customer code
 * @return {string} URL
 */
function apiKeysUrl(customerCode) {
    return `/timeseries/v2/${customerCode}/api-keys`;
}

/**
 * Gets API keys list.
 *
 * @param {Object}   params
 * @param {string}   params.customerCode Customer code
 * @param {Array}    params.fields Fields to retrieve, if none all
 * @param {Object}   params.filters List of filters
 * @return {Promise<ApiKey[]>} List of api keys
 */
export async function getAPIKeys({ customerCode, fields, filters, sort }) {
    const url = apiKeysUrl(customerCode);
    try {
        const response = await api.get(url, {
            headers: {
                Accept: 'application/json',
            },
            params: {
                ...(fields && { _fields: fields.join() }),
                ...(sort && { _sort: sort }),
                ...filters,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Create an API key
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {Object} params.apiKey       ApiKey to create
 * @return {Promise<void>}
 */
export async function createApiKey({ customerCode, item }) {
    try {
        const response = await api.post(apiKeysUrl(customerCode), item, {
            headers: { Accept: 'application/json' },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Delete an API key
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {string} params.id           ID of the API key to delete
 * @return {Promise<void>}
 */
export async function deleteAPIKey({ customerCode, id }) {
    const url = apiKeysUrl(customerCode);
    try {
        return api.delete(`${url}/${id}`, {
            headers: { Accept: 'application/json' },
        });
    } catch (error) {
        throw new Error(error.response.data);
    }
}

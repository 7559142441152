import Vue from 'vue';
import { hasRessourceAccess } from '@/router/navigation-guards.utils';

const Settings = () => import(/* webpackChunkName: "settings" */ '@/pages/Settings.vue');
const Configuration = () => import(/* webpackChunkName: "settings" */ '@/components/settings/Configuration.vue');
const CreateCategory = () =>
    import(/* webpackChunkName: "settings" */ '@/components/tags/creation-form/CreateCategory.vue');
const CreateDataType = () => import(/* webpackChunkName: "settings" */ '@/components/settings/CreateDataType.vue');
const CreateTag = () => import(/* webpackChunkName: "settings" */ '@/components/tags/creation-form/CreateTag.vue');
const ApiKeysList = () => import(/* webpackChunkName: "settings" */ '@/components/settings/ApiKeysList.vue');
const CreateApiKey = () => import(/* webpackChunkName: "settings" */ '@/components/settings/CreateApiKey.vue');
const DataTypesList = () => import(/* webpackChunkName: "settings" */ '@/components/settings/DataTypesList.vue');
const DataType = () => import(/* webpackChunkName: "settings" */ '@/components/settings/DataType.vue');
const Security = () => import(/* webpackChunkName: "settings" */ '@/components/settings/Security.vue');
const TagsList = () => import(/* webpackChunkName: "settings" */ '@/components/tags/TagsList.vue');
const Category = () => import(/* webpackChunkName: "settings" */ '@/components/tags/categories/Category.vue');
const Tag = () => import(/* webpackChunkName: "settings" */ '@/components/tags/Tag.vue');

export default {
    name: 'customer.settings',
    path: 'settings',
    redirect: { name: 'configuration' },
    component: Settings,
    meta: {
        title: 'SETTINGS_TITLE',
    },
    children: [
        {
            name: 'configuration',
            path: 'configuration',
            components: {
                default: Configuration,
            },
        },
        {
            name: 'security',
            path: 'security',
            components: {
                default: Security,
            },
        },
        {
            name: 'api-keys',
            path: 'api-keys',
            components: {
                default: ApiKeysList,
            },
        },
        {
            name: 'api-keys-new',
            path: 'api-keys/new',
            components: {
                fullPageLayer: CreateApiKey,
            },
        },
        {
            name: 'data-types',
            path: 'data-types',
            components: {
                default: DataTypesList,
                layer: DataType,
            },
            children: [
                {
                    name: 'data-type',
                    path: ':id',
                    redirect: { name: 'data-type-infos' },
                    children: [
                        {
                            name: 'data-type-infos',
                            path: 'infos',
                        },
                    ],
                },
            ],
        },
        {
            name: 'data-types-new',
            path: 'data-types/new',
            components: {
                fullPageLayer: CreateDataType,
            },
        },
        {
            name: 'categories-new',
            path: 'categories/new',
            components: {
                fullPageLayer: CreateCategory,
            },
        },
        {
            name: 'tags-new',
            path: 'tags/new',
            components: {
                fullPageLayer: CreateTag,
            },
        },
        {
            name: 'tags',
            path: 'tags',
            components: {
                default: TagsList,
                layer: Vue.extend({ template: `<router-view name="panel" />` }),
            },
            children: [
                {
                    name: 'category',
                    path: 'category/:id',
                    redirect: { name: 'category-infos' },
                    components: {
                        panel: Category,
                    },
                    children: [
                        {
                            name: 'category-infos',
                            path: 'infos',
                        },
                    ],
                },
                {
                    name: 'tag',
                    path: ':id',
                    redirect: { name: 'tag-infos' },
                    components: {
                        panel: Tag,
                    },
                    children: [
                        {
                            name: 'tag-infos',
                            path: 'infos',
                        },
                    ],
                },
            ],
        },
    ],
    async beforeEnter(to, from, next) {
        if (await hasRessourceAccess('update', 'Settings')) {
            next();
        } else {
            next({ name: 'customer', params: { customerCode: to.params.customerCode } });
        }
    },
};

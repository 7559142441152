import '@energiency/ui/dist/energiency-ui.css';
import 'vue';

// Note: moment is included by moment-timezone.
window.moment = require('!!moment-timezone');
window._ = require('!!lodash');
window.global = window;

require('!!babel-polyfill');

// CSS files
require('open-sans-fontface/open-sans.css');
require('font-awesome/css/font-awesome.css');

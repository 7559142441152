import axios from 'axios';
import api, { getAbortController } from './api.config';
import { uploadDocument } from './documents.service';

const abortControllers = {
    widgets: new Map(),
};

/**
 * Return the dashboards base URL.
 *
 * @param {string} customerCode Customer code
 * @return {string} URL
 */
function dashboardsBaseUrl(customerCode) {
    return `/timeseries/v2/${customerCode}/dashboards`;
}

/**
 * Retrieve all dashboards from the REST API.
 *
 * @param {Object}          params
 * @param {string}          params.customerCode     Customer code
 * @param {Array<string>}   params.fields           List of fields
 * @param {Object}          params.filters          List of filters
 * @param {Object}          params.sort             Sort
 * @return {Promise<Object[]>}  List of Dashboard data
 */
export async function getDashboards({ customerCode, fields, filters, sort }) {
    try {
        const response = await api.get(dashboardsBaseUrl(customerCode), {
            headers: {
                Accept: 'application/json',
            },
            params: {
                ...(fields && { _fields: fields.join() }),
                ...(sort && { _sort: sort }),
                ...filters,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Retrieve a given dashboard from the REST API.
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {string} params.id ID of the dashboard to retrieve
 * @return {Promsie<Object>} Dashboard data
 */
export async function getDashboard({ customerCode, id, shareLinkId }) {
    try {
        const response = await api.get(`${dashboardsBaseUrl(customerCode)}/${id}`, {
            headers: {
                Accept: 'application/json',
            },
            params: {
                shareLinkId,
            },
        });

        return response.data;
    } catch (error) {
        throw error.response;
    }
}
/**
 * Export a dashbaord to PDF
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {string} params.id           ID of the dashboard to export
 * @param {string}  params.startDate   Start date as ISO8601 (included)
 * @param {string}  params.endDate     End date as ISO8601 (excluded)
 * @param {string} params.timestep     Timestep as ISO-8601 duration
 * @returns {Promise<Object>}
 */
export async function generatePDF({ customerCode, id, startDate, endDate, timestep }) {
    try {
        const response = await api.post(
            `${dashboardsBaseUrl(
                customerCode,
            )}/${id}/pdf?startDate=${startDate}&endDate=${endDate}&timestep=${timestep}`,
            {
                headers: {
                    Accept: 'application/json',
                },
            },
        );
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Create a dashboard.
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {Object} params.dashboard    Data of the dashboard to create
 * @return {Promise<Object>} The created dashboard with an id
 */
export async function createDashboard({ customerCode, dashboard, copyFrom }) {
    try {
        const response = await api.post(dashboardsBaseUrl(customerCode), dashboard, {
            headers: {
                Accept: 'application/json',
            },
            ...(dashboard && copyFrom ? { params: { copyFrom } } : {}),
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Delete a dashboard
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {string} params.id ID of the dashboard to delete
 * @returns {Promise<void>}
 */
export async function deleteDashboard({ customerCode, id }) {
    try {
        await api.delete(`${dashboardsBaseUrl(customerCode)}/${id}`, { headers: { Accept: 'application/json' } });
    } catch (error) {
        throw new Error(error.response);
    }
}

/**
 * Update a dashboard.
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {Object} params.dashboard    Data of the dashboard to update
 * @return {Promise<Object>} The updated dashboard
 */
export async function updateDashboard({ customerCode, dashboard, cancellable = false }) {
    try {
        abortControllers.update = getAbortController(abortControllers.update);
        const response = await api.put(`${dashboardsBaseUrl(customerCode)}/${dashboard.id}`, dashboard, {
            headers: { Accept: 'application/json' },
            ...(cancellable && { signal: abortControllers.update.signal }),
        });
        return response.data;
    } catch (error) {
        if (!axios.isCancel(error)) {
            throw new Error(error.response.data);
        }
    }
}

/**
 * Move a dashboard.
 *
 * @param {Object} params
 * @param {string} params.dashboardId The dashboard id
 * @param {string} params.previousId The new previous item id
 * @param {Object} params.nextId     The new next item id
 * @return {Promise<Object>} The moved dashboard
 */
export async function moveDashboard({ customerCode, dashboardId, previousId, nextId }) {
    try {
        const response = await api.put(
            `${dashboardsBaseUrl(customerCode)}/${dashboardId}/move`,
            {
                previousId,
                nextId,
            },
            {
                headers: {
                    Accept: 'application/json',
                },
            },
        );
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Update a widget.
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {string} params.id        ID of the dashboard to update
 * @param {Object} params.widget    Data of the widget to update
 * @return {Promise<Object>} The updated widget
 */
export async function updateWidget({ customerCode, id, widget, cancellable = false }) {
    try {
        abortControllers.widgets.set(widget.id, getAbortController(abortControllers.widgets.get(widget.id)));

        let data = widget;

        // If the widget has a background image, we need to send it to /documents API
        if (widget.options?.backgroundImage && widget.options?.backgroundImage instanceof File) {
            const newDocument = await uploadDocument({
                customerCode,
                subjectType: 'Dashboard',
                subjectId: id,
                document: widget.options.backgroundImage,
            });
            data = {
                ...widget,
                options: {
                    ...widget.options,
                    backgroundImage: newDocument.documentUrl,
                },
            };
        }

        const response = await api.put(`${dashboardsBaseUrl(customerCode)}/${id}/widgets/${widget.id}`, data, {
            headers: { Accept: 'application/json' },
            ...(cancellable && { signal: abortControllers.widgets.get(widget.id).signal }),
        });
        abortControllers.widgets.delete(widget.id);
        return response.data;
    } catch (error) {
        console.error(error);
        if (!axios.isCancel(error)) {
            throw new Error(error.response.data);
        }
    }
}

/**
 * Delete a widget.
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {string} params.id        ID of the dashboard to delete
 * @param {Object} params.widget    ID of the widget to delete
 * @return {Promise<void>}
 */
export async function deleteWidget({ customerCode, id, widgetId }) {
    try {
        await api.delete(`${dashboardsBaseUrl(customerCode)}/${id}/widgets/${widgetId}`, {
            headers: { Accept: 'application/json' },
        });
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Update all report of a dashboard.
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {string} params.id        ID of the report to update
 * @return {Object} The updated reports
 */
export async function updateReports({ customerCode, id }) {
    try {
        const response = await api.put(`${dashboardsBaseUrl(customerCode)}/${id}/reports`, {
            headers: { Accept: 'application/json' },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

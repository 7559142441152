import axios from 'axios';
import { omit } from 'lodash';
import api, { getAbortController } from './api.config';

const abortControllers = {};

export const perimeterTypes = {
    SITE: 'site',
    FOLDER: 'folder',
    METER: 'meter',
};

/**
 * Return the perimeter resource URL.
 *
 * @param {string} customerCode Customer code
 * @return {string} URL
 */
function perimeterUrl(customerCode) {
    return `/timeseries/v2/${customerCode}/perimeter`;
}

function getElementTypeUrl(customerCode, elementType) {
    return `/timeseries/v2/${customerCode}/${elementType}s`;
}

/**
 * Return the relation resource URL.
 *
 * @param {string} customerCode Customer code
 * @return {string} URL
 */
function relationUrl(customerCode) {
    return `/timeseries/v2/${customerCode}/relation`;
}

/**
 * Retrieve all perimeter elements from the REST API.
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @return {Promise<Object[]>} List of perimeter elements
 */
export async function getPerimeter({ customerCode }) {
    try {
        const response = await api.get(perimeterUrl(customerCode), {
            headers: {
                Accept: 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Retrieve the perimeter as XLSX file from the REST API.
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @return {Promise<Object[]>} List of perimeter elements
 */
export async function getPerimeterAsXLSX({ customerCode, lang = 'en' }) {
    try {
        const response = await api.get(perimeterUrl(customerCode), {
            headers: {
                Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'Accept-Language': lang,
            },
            responseType: 'blob',
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Retrieve a perimeter element from the REST API.
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {string} params.id The element id
 * @param {string} params.type The element type
 * @return {Promise<Object>} Perimeter element
 */
export async function getPerimeterElement({ customerCode, id, type }) {
    try {
        const response = await api.get(`${getElementTypeUrl(customerCode, type)}/${id}`, {
            headers: {
                Accept: 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Call the API to create a perimeter element.
 *
 * @param {Object} params
 * @param {string} customerCode Customer code
 * @param {Object} element      Data of the element to create
 */
export async function createPerimeterElement({ customerCode, element, copyFrom }) {
    try {
        const response = await api.post(`${getElementTypeUrl(customerCode, element.type)}`, element, {
            headers: {
                Accept: 'application/json',
            },
            ...(element && copyFrom ? { params: { copyFrom } } : {}),
        });
        return {
            ...response.data,
            type: element.type,
        };
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Call the API to update a perimeter element.
 *
 * @param {Object} params
 * @param {string} customerCode Customer code
 * @param {Object} element      New data of the element
 */
export async function updatePerimeterElement({ customerCode, element }) {
    try {
        const updatedElement = {
            ...omit(element, ['creationUserId', 'creationDate', 'modificationUserId', 'modificationDate']),
            description: element.description ?? '',
        };
        const response = await api.put(
            `${getElementTypeUrl(customerCode, element.type)}/${element.id}`,
            updatedElement,
            {
                headers: {
                    Accept: 'application/json',
                },
            },
        );
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Call the API to delete a perimeter element.
 *
 * @param {Object} params
 * @param {string} customerCode Customer code
 * @param {Object} element      Data of the element to delete
 */
export async function deletePerimeterElement({ customerCode, element }) {
    try {
        const response = await api.delete(`${getElementTypeUrl(customerCode, element.type)}/${element.id}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Call the API to check the validity of a virtual relation.
 *
 * @param {string}  customerCode  CustomerCode
 * @param {string}  relation
 * @param {string}  meterId
 * @param {string}  cancellable
 * @return {Object} Response of the relation validator
 */
export async function checkRelation({ customerCode, relation, meterId = null, cancellable = false }) {
    try {
        abortControllers.checkRelation = getAbortController(abortControllers.checkRelation);
        const response = await api.post(
            relationUrl(customerCode),
            { relation, ...(meterId && { meterId }) },
            {
                headers: {
                    Accept: 'application/json',
                },
                ...(cancellable && { signal: abortControllers.checkRelation.signal }),
            },
        );

        return response.data;
    } catch (error) {
        if (!axios.isCancel(error)) {
            throw new Error(error.response.data);
        }
    }
}

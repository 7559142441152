import api from './api.config';

/**
 * @typedef {Object} Event
 * @property {string} subjectId Date of the first (oldest) data, as ISO-8601 string
 * @property {string} subjectType  Date of the last (newest) data, as ISO-8601 string
 * @property {string} subjectName     Start date of the import, as ISO-8601 string
 * @property {string} action      Stop date of the import, as ISO-8601 string
 */

/**
 * Returns the event resource URL.
 *
 * @param {string} customerCode Customer code
 * @return {string} URL
 */
function eventsUrl(customerCode) {
    return `/timeseries/v2/${customerCode}/events`;
}
/**
 * Gets event list.
 *
 * @param {Object}   params
 * @param {string}   params.customerCode Customer code
 * @param {Object}   params.dateRange Date range
 * @param {Object}   params.filters List of filters
 * @param {Object}   params.sort Sort
 * @param {Object}   params.page number of page
 * @param {Object}   params.perPage number of items per Page
 * @return {Event} List of event
 */
export async function getEvents({ customerCode, fields, filters, sort, page, perPage }) {
    const url = eventsUrl(customerCode);
    try {
        const response = await api.get(url, {
            headers: {
                Accept: 'application/json',
            },
            params: {
                _page: page,
                _perPage: perPage,
                _sort: sort,
                ...(fields && { _fields: fields.join() }),
                ...filters,
            },
        });

        return { data: response.data, total: parseInt(response.headers['x-items-count']) };
    } catch (error) {
        throw new Error(error.response ? error.response.data : error);
    }
}

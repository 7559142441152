import api from './api.config';

/**
 * @typedef {Object} AlertConfiguration
 * @property {number} id               ID of the alert configuration
 * @property {string} name             Name
 * @property {string} description      Description
 * @property {string} timestep         Timestep used for aggregation of quantities
 * @property {string} delay            Delay used to compute an alert
 * @property {string} message          Message send in alert mail
 */

/**
 * @typedef {Object} SimpleAlertConfigurationToCreate
 * @property {string} name             Name
 * @property {number} threshold        Threshold value
 * @property {string} comparator       Comparator (enum: `>`, `<`, `=`, `<=`, `>=`)
 * @property {string} description      Description
 * @property {string} timestep         Timestep used for aggregation of quantities
 * @property {string} timezone         Computed data will be converted to this timezone
 * @property {string} message          Message send in alert mail
 */

/**
 * @typedef {Object} AlertStatus
 * @property {string} alertConfigurationId ID of the related alert configuration
 * @property {string} startDate            Start of the alert, as ISO-8601 string
 * @property {string} endDate              End of the alert, as ISO-8601 string
 * @property {number} avg                  Average of the quantity (computed with the `quantityRelation` of the alert configuration)
 * @property {number} sum                  Sum of the quantity (computed with the `quantityRelation` of the alert configuration)
 */

/**
 * Return the timeseries base URL.
 *
 * @param {string} customerCode Customer code
 * @return {string} URL
 */
function timeseriesBaseUrl(customerCode) {
    return `/timeseries/v2/${customerCode}`;
}

/**
 * Return the alert configuration  URL.
 *
 * @param {string} customerCode Customer code
 * @return {string} URL
 */
function alertConfigurationUrl(customerCode) {
    return `${timeseriesBaseUrl(customerCode)}/alert-configurations`;
}

/**
 * Return the alert status  URL.
 *
 * @param {string} customerCode Customer code
 * @return {string} URL
 */
function alertStatusUrl(customerCode) {
    return `${timeseriesBaseUrl(customerCode)}/alert-status`;
}

/**
 * Retrieve status of all alerts from the REST API.
 *
 * @param {Object}  params
 * @param {string}  params.customerCode Customer code
 * @param {Object}   params.filters List of filters
 * @return {AlertStatus[]} Alerts
 */
export async function getAlertStatus({ customerCode, filters }) {
    try {
        const response = await api.get(alertStatusUrl(customerCode), {
            headers: {
                Accept: 'application/json',
            },
            params: {
                ...filters,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Retrieve all alerts configurations from the REST API.
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @return {AlertConfiguration[]} Alerts
 */
export async function getAlertConfigurations({ customerCode }) {
    try {
        const response = await api.get(alertConfigurationUrl(customerCode), {
            headers: {
                Accept: 'application/json',
            },
            params: {
                _perPage: 1000,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Retrieve status of alerts export from the REST API.
 *
 * @param {Object}  params
 * @param {Boolean} params.includeInactive Include alertStatus with level = 0
 * @param {Date}    params.startDate Start date
 * @param {Date}    params.endDate   End date
 * @param {string}  params.lang      Language to use for column headers
 * @return {Promise<AlertStatus[]>} Alerts
 */
export async function getAlertsExport({ customerCode, startDate, endDate, lang = 'en' }) {
    try {
        const response = await api.get(alertStatusUrl(customerCode), {
            headers: {
                Accept: 'text/csv',
                'Accept-Language': lang,
            },
            params: {
                ...(startDate || endDate
                    ? {
                          startDate_gte: startDate.toISOString(),
                          endDate_lt: endDate.toISOString(),
                      }
                    : { isPending: true }),
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Create an alert configuration.
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {SimpleAlertConfigurationToCreate} params.alertConfiguration A simple alert configuration to create
 * @return {Promise<AlertConfiguration>} The created alert configuration
 */
export async function createAlertConfiguration({ customerCode, alertConfiguration }) {
    try {
        const response = await api.post(alertConfigurationUrl(customerCode), alertConfiguration, {
            headers: {
                Accept: 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Update an alert configuration.
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {string} params.id The alert configuration ID
 * @param {Object} params.alertConfiguration A simple alert configuration to update
 */
export async function updateAlertConfiguration({ customerCode, id, alertConfiguration }) {
    try {
        const response = await api.put(`${alertConfigurationUrl(customerCode)}/${id}`, alertConfiguration, {
            headers: {
                Accept: 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Delete an alert configuration from the REST API.
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {string} params.id The alert configuration ID
 */
export async function deleteAlertConfiguration({ customerCode, id }) {
    try {
        await api.delete(`${alertConfigurationUrl(customerCode)}/${id}`, {
            headers: {
                Accept: 'application/json',
            },
        });
    } catch (error) {
        throw new Error(error.response);
    }
}

import * as Sentry from '@sentry/vue';

export function initTag(user, customerCode) {
    if (process.env.ENABLE_SENTRY) {
        Sentry.setTag('user.login', user.login);
        Sentry.setTag('user.language', user.language);
        Sentry.setTag('user.role', user.role);
        Sentry.setTag('customerCode', customerCode);
    }
}

<template>
    <UiDropdown v-if="currentUser" position="bottom-start">
        <template #trigger>
            <UiButton class="flex items-center menu" color="white" appearance="flat" data-test="user-menu-button">
                <UiIcon class="mr2" icon-name="far user" size="medium" />
                <span>{{ currentUser.firstName + ' ' + currentUser.lastName }}</span>
            </UiButton>
        </template>
        <UiDropdownMenu>
            <UiButton
                class="actions"
                appearance="flat"
                tag-name="router-link"
                text-align="left"
                :to="{ name: 'customer.profile', params: { customerCode } }"
                data-test="user-edition-form-button"
            >
                <UiIcon class="pr2" icon-name="user" size="small" />
                <span>{{ $t('PROFILE_MENU') }}</span>
            </UiButton>
            <div class="divider" />
            <UiButton
                class="actions"
                appearance="flat"
                text-align="left"
                data-test="logout-button"
                @click="logout({ router: $router })"
            >
                <UiIcon class="pr2" icon-name="right-from-bracket" size="small" />
                <span>{{ $t('DISCONNECT_MENU') }}</span>
            </UiButton>
        </UiDropdownMenu>
    </UiDropdown>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { NAMESPACE as NS_USER } from '@/store/modules/users';
import { FETCH_CURRENT_USER as fetchCurrentUser, LOGOUT as logout } from '@/store/modules/users/action-types';
import { UiButton, UiDropdown, UiDropdownMenu, UiIcon } from '@energiency/ui';

export default {
    components: {
        UiDropdown,
        UiDropdownMenu,
        UiButton,
        UiIcon,
    },
    computed: {
        ...mapState(NS_USER, ['currentUser']),
        ...mapGetters(['customerCode']),
    },
    async created() {
        await this.fetchCurrentUser();
    },
    methods: {
        ...mapActions(NS_USER, {
            fetchCurrentUser,
            logout,
        }),
    },
};
</script>
<style lang="less" scoped>
@import (reference) '~style/navbar.less';
@import (reference) '~style/size.less';
@import (reference) '~style/colors.less';

.UiDropdownMenu {
    margin-top: 1.3em;
}

.UiButton.menu:not(.hover) {
    span {
        font-weight: 800;
        font-size: 0.85em;
    }
}

.divider {
    margin: 0;
}

.UiButton.actions:hover:not(.active) {
    color: var(--primary-color);
    .UiIcon {
        color: var(--primary-color);
    }
}
</style>

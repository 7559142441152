import { hasRessourceAccess } from '@/router/navigation-guards.utils';

const Imports = () => import(/* webpackChunkName: "imports" */ '@/pages/Imports.vue');
const Import = () => import(/* webpackChunkName: "imports" */ '@/components/imports/Import.vue');
const ImportsList = () => import(/* webpackChunkName: "imports" */ '@/components/imports/ImportsList.vue');
const CreateImport = () => import(/* webpackChunkName: "imports" */ '@/components/imports/CreateImport.vue');
const ImportAlert = () => import(/* webpackChunkName: "imports" */ '@/components/imports/alerts/ImportAlert.vue');
const ImportAlertsList = () =>
    import(/* webpackChunkName: "imports" */ '@/components/imports/alerts/ImportAlertsList.vue');
const CreateImportAlert = () =>
    import(/* webpackChunkName: "imports" */ '@/components/imports/alerts/CreateImportAlert.vue');

export default {
    name: 'customer.imports',
    path: 'imports',
    redirect: { name: 'imports' },
    component: Imports,
    meta: {
        title: 'IMPORTS_TITLE',
    },
    async beforeEnter(to, from, next) {
        if ((await hasRessourceAccess('read', 'Import')) || (await hasRessourceAccess('read', 'ImportAlert'))) {
            next();
        } else {
            next({ name: 'customer', params: { customerCode: to.params.customerCode } });
        }
    },
    children: [
        {
            name: 'import-alerts-new',
            path: 'alerts/new',
            components: {
                fullPageLayer: CreateImportAlert,
            },
        },
        {
            name: 'import-alerts',
            path: 'alerts',
            components: {
                default: ImportAlertsList,
                layer: ImportAlert,
            },
            children: [
                {
                    name: 'import-alert',
                    path: ':id',
                },
            ],
        },
        {
            name: 'imports-new',
            path: 'new',
            components: {
                fullPageLayer: CreateImport,
            },
        },
        {
            name: 'imports',
            path: '',
            components: {
                default: ImportsList,
                layer: Import,
            },
            children: [
                {
                    name: 'import',
                    path: ':id',
                },
            ],
        },
    ],
};

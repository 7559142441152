import Cookies from 'js-cookie';
import Vue from 'vue';
import createPersistedState from 'vuex-persistedstate';
import { getShareLinkParams } from '@/utils/sharing.utils';
import Vuex from 'vuex';
import actions from './modules/actions';
import alerts from './modules/alerts';
import apiKeys from './modules/api-keys';
import categories from './modules/categories';
import clipboard from './modules/clipboard';
import dashboards from './modules/dashboards';
import dataTypes from './modules/data-types';
import documents from './modules/documents';
import events from './modules/events';
import groups from './modules/groups';
import imports from './modules/imports';
import meters from './modules/meters';
import reports from './modules/reports';
import search from './modules/search';
import settings from './modules/settings';
import tags from './modules/tags';
import tasks from './modules/tasks';
import users from './modules/users';

Vue.use(Vuex);

// eslint-disable-next-line
const debug = process.env.NODE_ENV !== 'production';

/**
 * Create the store
 *
 * @return {Vuex.Store} The store
 */
export function createStore() {
    const modules = {
        dashboards,
        reports,
        alerts,
        settings,
        meters,
        imports,
        actions,
        tasks,
        users,
        search,
        documents,
        dataTypes,
        events,
        groups,
        clipboard,
        tags,
        categories,
        apiKeys,
    };
    return new Vuex.Store({
        strict: debug,
        modules,
        plugins: [
            createPersistedState({
                key: 'energiency-solution',
                paths: Object.entries(modules).reduce(
                    (acc, [, module]) => [
                        ...acc,
                        ...(typeof module.getPersistedStatePaths === 'function' ? module.getPersistedStatePaths() : []),
                    ],
                    [],
                ),
            }),
        ],
        getters: {
            customerCode(state, getters, rootState) {
                return (
                    getShareLinkParams(rootState.route)?.customerCode ??
                    rootState.route?.params?.customerCode ??
                    Cookies.get('customerCode')
                );
            },
        },
    });
}

// create a store once
let _store;
export function getStore() {
    if (!_store) {
        _store = createStore();
    }
    return _store;
}

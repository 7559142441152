import { getAllSearches, getSearch } from '@/api/search.service';
import { FETCH_ALL_SEARCHES, FETCH_SEARCH } from './action-types';
import { FETCH_ALL_SEARCHES_SUCCESS, FETCH_SEARCH_SUCCESS } from './mutation-types';

/** @type {SearchState} */
const _state = {
    results: {},
};

const actions = {
    /**
     * Fetch search result.
     *
     * @param {Object} context
     * @param {Object} params
     * @param {string} params.category search category
     * @param {string} params.filterValue filter value
     */
    async [FETCH_SEARCH]({ commit, rootGetters }, { category, filterValue }) {
        try {
            const searchResult = await getSearch({
                customerCode: rootGetters.customerCode,
                category,
                filterValue,
            });
            commit(FETCH_SEARCH_SUCCESS, {
                category,
                searchResult,
            });
        } catch (error) {
            console.error(error);
        }
    },

    /**
     * Fetch all search result.
     *
     * @param {Object} context
     * @param {String} filterValue
     */
    async [FETCH_ALL_SEARCHES]({ commit, rootGetters }, filterValue) {
        try {
            const searchResult = await getAllSearches({
                customerCode: rootGetters.customerCode,
                filterValue,
            });
            commit(FETCH_ALL_SEARCHES_SUCCESS, searchResult);
        } catch (error) {
            console.error(error);
        }
    },
};
const mutations = {
    [FETCH_SEARCH_SUCCESS](state, { category, searchResult }) {
        state.results[category] = searchResult;
    },
    [FETCH_ALL_SEARCHES_SUCCESS](state, searchResults) {
        state.results = searchResults;
    },
};
const getters = {
    /**
     * Return a search result by category
     */
    getSearchResultByCategory: (state) => (category) => {
        return state.results[category] || [];
    },
    /**
     * Return a search result by category
     */
    hasAnyResult: (state) => {
        return Object.keys(state.results).some((key) => state.results[key].length > 0);
    },
};

export const NAMESPACE = 'search';

export default {
    namespaced: true,
    state: _state,
    actions,
    mutations,
    getters,
};

import api from './api.config';

/**
 * Return the tags resource URL.
 *
 * @param {string} customerCode Customer code
 * @return {string} URL
 */
function tagsUrl(customerCode) {
    return `/timeseries/v2/${customerCode}/tags`;
}

/**
 * Get all tags
 *
 * @param {Object} params
 * @param {string} params.customerCode
 * @param {Array} params.fiels
 * @param {Object} params.filters
 * @returns {Promise<Tag[]>}
 */
export async function getTags({ customerCode, filters, page, perPage, search, sort }) {
    try {
        const response = await api.get(tagsUrl(customerCode), {
            headers: { Accept: 'application/json' },
            params: {
                ...filters,
                ...(page && { _page: page }),
                ...(perPage && { _perPage: perPage }),
                ...(search && { _search: search }),
                ...(sort && { _sort: sort }),
            },
        });
        return { data: response.data, total: parseInt(response.headers['x-items-count']) };
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Update a tag
 * @param {Object} params
 * @param {string} params.customerCode the customer code
 * @param {Promise<Tag>} params.Tag The updated tag
 */
export async function updateTag({ tag, customerCode }) {
    try {
        const response = await api.put(`${tagsUrl(customerCode)}/${tag.id}`, tag, {
            headers: { Accept: 'application/json' },
        });
        return response?.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Create a tag.
 *
 * @param {Object} params
 * @param {String} params.customerCode the customer code
 * @param {Tag} params.tag the tag to create
 * @returns {Promise<Tag>} the created tag
 */
export async function createTag({ tag, customerCode }) {
    try {
        const response = await api.post(tagsUrl(customerCode), tag, { headers: { Accept: 'application/json' } });
        return response?.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Delete a Tag.
 *
 * @param {Object}
 * @param {string} params.customerCode the customer code
 * @param {string} params.id The id of the Tag to delete
 */
export async function deleteTag({ id, customerCode }) {
    try {
        await api.delete(`${tagsUrl(customerCode)}/${id}`, { headers: { Accept: 'application/json' } });
    } catch (error) {
        throw new Error(error.response.data);
    }
}

import { t } from 'i18next';
import _ from 'lodash';
import { confirmModal } from '@energiency/ui';

export const actionsTypes = {
    SAVE_EDITION: 'SAVE_EDITION',
};

export const mutationTypes = {
    SAVING_EDITION: 'SAVING_EDITION',
    SAVE_EDITION_SUCCESS: 'SAVE_EDITION_SUCCESS',
    SAVE_EDITION_ERROR: 'SAVE_EDITION_ERROR',
};

/**
 * Get a VueX store or module, with edition feature.
 *
 * To add edition in a module, simply call:
 *
 * ```js
 * import { addEdition } from '@/store/utils/edition.utils';
 *
 * addEdition(myModule);
 * ```
 *
 *
 * ```js
 * import {
 *   actionsTypes as editionActionsTypes,
 * } from '@/store/utils/edition.utils';
 *
 * ...mapState(moduleNamespace, {
 *   isSaving: state => state.edition.isSaving,
 * }),
 *
 * ...mapActions(moduleNamespace, {
 *   saveEdition: editionActionsTypes.SAVE_EDITION,
 * }),
 
 * ```
 *
 * @param {Object}   store
 * @param {Object}   options
 * @param {Function} options.saveFunction Function to call to save
 * @param {string}   options.namespace    String used as namespaced nested module name
 * @return {Object} Store with added feature
 */
export function addEdition(store, { saveFunction, namespace = '' }) {
    const moduleName = namespace || 'edition';
    return _.merge({}, store, {
        modules: {
            [moduleName]: {
                namespaced: !!namespace,
                state: {
                    isSaving: false,
                    saveErrors: [],
                },
                actions: {
                    /**
                     * Start the edition of a given item.
                     */
                    async [actionsTypes.SAVE_EDITION]({ commit, ...context }, { item }) {
                        try {
                            commit(mutationTypes.SAVING_EDITION);
                            await saveFunction({ commit, ...context }, item);
                            commit(mutationTypes.SAVE_EDITION_SUCCESS);
                        } catch ({ errors }) {
                            commit(mutationTypes.SAVE_EDITION_ERROR, {
                                errors,
                            });
                        }
                    },
                },
                mutations: {
                    [mutationTypes.SAVING_EDITION](state) {
                        state.isSaving = true;
                    },
                    [mutationTypes.SAVE_EDITION_SUCCESS](state) {
                        state.isSaving = false;
                        state.saveErrors = [];
                    },
                    [mutationTypes.SAVE_EDITION_ERROR](state, { errors }) {
                        state.isSaving = false;
                        state.saveErrors = errors;
                    },
                },
            },
        },
    });
}

/**
 * Method to display a deletion modal dialog.
 * @return {Promise<Boolean>} The result of the modal dialog
 */

export function confirmDeletion(html = null) {
    const labels = {
        title: t(`ASK_DELETE_ELEMENT_TEXT`),
        html,
        confirmButtonText: t(`CONFIRM_DELETE_ELEMENT_TEXT`),
        cancelButtonText: t(`CANCEL_TEXT`),
    };
    return confirmModal({ labels, showConfirmButton: true });
}

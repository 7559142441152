import api from './api.config';

/**
 * @typedef {Object} Settings
 * @property {Object} appearance
 * @property {Object} authenticationPolicies
 * @property {Object} features
 * @property {string} name
 * @property {Array<string>} timesteps
 * @property {string} timezone
 */

/**
 * Return the settings resource URL.
 *
 * @param {string} customerCode Customer code
 * @return {string} URL
 */
function settingsUrl(customerCode) {
    return `/timeseries/v2/${customerCode}/settings`;
}

/**
 * Retrieve settings from the REST API.
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @return {Promise<Settings>} Settings
 */
export async function getSettings({ customerCode, shareLinkId, dashboardId }) {
    try {
        const response = await api.get(
            settingsUrl(customerCode),
            {
                ...(shareLinkId && dashboardId
                    ? {
                          params: {
                              shareLinkId,
                              dashboardId,
                          },
                      }
                    : {}),
            },
            {
                headers: {
                    Accept: 'application/json',
                },
            },
        );
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Update settings.
 *
 * @param {Object}   params
 * @param {string}   params.customerCode Customer code
 * @param {Settings} params.settings New settings
 * @return {Promise<Settings>} Updated settings
 */
export async function updateSettings({ customerCode, settings }) {
    let payload = settings;

    // add files content
    const [files, values] = partitionObject(settings, isFile);
    const fileEntries = Object.entries(files);
    if (fileEntries.length > 0) {
        const formData = new FormData();
        for (const [key, file] of fileEntries) {
            formData.append(key, file);
        }
        const jsonPayload = new Blob([JSON.stringify(values)], { type: 'application/json' });
        formData.append('jsonPayload', jsonPayload, 'jsonPayload');
        payload = formData;
    }

    let response;
    try {
        response = await api.put(settingsUrl(customerCode), payload, {
            headers: {
                Accept: 'application/json',
            },
        });
    } catch (error) {
        throw new Error(error.response.data);
    }

    return response.data;
}

/**
 * Partition object properties
 * @param {Object} object
 * @param {Function} predicate
 * @returns {Array}
 */
function partitionObject(object, predicate) {
    return Object.entries(object).reduce(
        (res, [key, value]) => {
            const index = predicate(value) ? 0 : 1;
            res[index][key] = value;
            return res;
        },
        [{}, {}],
    );
}
/**
 * True if File, otherwise false
 * @param {*} value
 * @returns {boolean}
 */
function isFile(value) {
    return value instanceof File || Object.prototype.toString.call(value) === '[object File]';
}

import { hasRessourceAccess } from '@/router/navigation-guards.utils';

// Activity Log
const ActivityLog = () => import(/* webpackChunkName: "activity-log" */ '@/pages/ActivityLog.vue');

export default {
    name: 'customer.activity',
    path: 'activity',
    component: ActivityLog,
    meta: {
        title: 'ACTIVITY_LOG_TITLE',
    },
    async beforeEnter(to, from, next) {
        if (await hasRessourceAccess('read', 'Event')) {
            next();
        } else {
            next({ name: 'customer', params: { customerCode: to.params.customerCode } });
        }
    },
};

import { t } from 'i18next';
import { deleteDocument, getDocuments, updateDocument, uploadDocument } from '@/api/documents.service';
import { formatFileSize } from '@/helpers/locales';
import { notify } from '@/helpers/notifications';
import dataListModule from './data-list.module';

export const actionTypes = {
    UPLOAD_DOCUMENT: 'UPLOAD_DOCUMENT',
    UPDATE_DOCUMENT: 'UPDATE_DOCUMENT',
};

export const mutationTypes = {
    UPLOAD_DOCUMENT_SUCCESS: 'UPLOAD_DOCUMENT_SUCCESS',
    UPLOAD_DOCUMENT_ERROR: 'UPLOAD_DOCUMENT_ERROR',
    UPDATE_DOCUMENT_SUCCESS: 'UPDATE_DOCUMENT_SUCCESS',
    UPLOADING: 'UPLOADING',
};

const MAX_DOCUMENT_SIZE = 10000000;

export default function ({ subjectType = null, getSubjectId, updateSubject } = {}) {
    const _state = {
        isUploading: false,
    };
    return {
        state: _state,
        mutations: {
            [mutationTypes.UPLOADING](state) {
                state.isUploading = true;
            },
            /**
             * @param {Object} state
             * @param {Object} payload
             * @param {int}    payload.index Index of the document mock
             * @param {object} payload.document Added document
             */
            [mutationTypes.UPLOAD_DOCUMENT_SUCCESS](state, { document }) {
                state.isUploading = false;
                state.documents.items = [...(state.documents.items ?? []), document];
                notify({
                    type: 'success',
                    text: t('UPLOAD_DOCUMENT_SUCCESS'),
                });
            },
            [mutationTypes.UPLOAD_DOCUMENT_ERROR](state) {
                state.isUploading = false;
                notify({
                    type: 'error',
                    text: t('UPLOAD_DOCUMENT_ERROR'),
                });
            },
            /**
             * @param {Object} state
             * @param {Object} payload
             * @param {String} payload.document The document to updated
             */
            [mutationTypes.UPDATE_DOCUMENT_SUCCESS](state, { document }) {
                const index = state.documents.items.findIndex((f) => f.id === document.id);
                if (index >= 0) {
                    state.documents.items.splice(index, 1, { ...document });
                }
            },
        },
        actions: {
            async [actionTypes.UPLOAD_DOCUMENT](context, document) {
                context.commit(mutationTypes.UPLOADING);
                try {
                    if (document.size > MAX_DOCUMENT_SIZE) {
                        notify({
                            type: 'error',
                            text: t('DOCUMENTS_UPLOAD_ERROR_MAX_SIZE', { maxSize: formatFileSize(10) }),
                        });
                    } else {
                        const newDocument = await uploadDocument({
                            customerCode: context.rootGetters.customerCode,
                            ...(getSubjectId && { subjectId: getSubjectId(context) }),
                            ...(subjectType && { subjectType }),
                            parentId: document.parentId,
                            document,
                        });
                        context.commit(mutationTypes.UPLOAD_DOCUMENT_SUCCESS, {
                            document: newDocument,
                        });
                        if (subjectType === 'Action') {
                            await updateSubject(context, getSubjectId(context));
                        }
                        return newDocument.documentUrl;
                    }
                } catch (error) {
                    context.commit(mutationTypes.UPLOAD_DOCUMENT_ERROR);
                }
            },

            async [actionTypes.UPDATE_DOCUMENT](context, document) {
                try {
                    const updatedDocument = await updateDocument({
                        customerCode: context.rootGetters.customerCode,
                        document,
                    });
                    context.commit(mutationTypes.UPDATE_DOCUMENT_SUCCESS, { document: updatedDocument });

                    notify({
                        type: 'success',
                        text: t('DOCUMENT_UPDATE_SUCCESS'),
                    });
                } catch (error) {
                    notify({
                        type: 'error',
                        text: t('DOCUMENT_UPDATE_ERROR'),
                    });
                }
            },
        },
        modules: {
            documents: dataListModule({
                namespaced: false,
                getItems(context, { filters }) {
                    return getDocuments({
                        customerCode: context.rootGetters.customerCode,
                        filters: {
                            ...filters,
                            isTemporary: false,
                            ...(getSubjectId && { subjectId: getSubjectId(context) }),
                        },
                    });
                },
                async deleteItem(context, { item }) {
                    try {
                        await deleteDocument({ customerCode: context.rootGetters.customerCode, id: item.id });
                        notify({
                            type: 'success',
                            text: t('DELETE_DOCUMENT_SUCCESS'),
                        });

                        if (updateSubject) {
                            await updateSubject(context, getSubjectId(context));
                        }
                    } catch (error) {
                        notify({
                            type: 'error',
                            text: t('DELETE_DOCUMENT_ERROR'),
                        });
                    }
                },
            }),
        },
    };
}

import api from './api.config';

const searchCategories = ['top', 'monitoring', 'report', 'perimeter'];

/**
 * Return the search resource URL.
 *
 * @param {string} customerCode Customer code
 * @param {string} category category
 * @param {string} filterValue filter value
 * @return {string} URL
 */
function searchUrl(customerCode, category, filterValue) {
    category = category === 'top' ? '' : category;
    return `/search/${category}?filter=${filterValue}`;
}

/**
 * Retrieve all searches query from the REST API.
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {string} params.filterValue filter value
 * @return {Search} Search
 */
export async function getAllSearches({ customerCode, filterValue }) {
    try {
        const searchResults = {};
        const results = await Promise.all(
            searchCategories.map((category) => getSearch({ customerCode, category, filterValue })),
        );
        results.forEach((result, index) => {
            searchResults[searchCategories[index]] = result;
        });
        return searchResults;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Retrieve search from the REST API.
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {string} params.category category
 * @param {string} params.filterValue filter value
 * @return {Search} Search
 */
export async function getSearch({ customerCode, category, filterValue }) {
    if (searchCategories.indexOf(category) === -1) {
        throw new Error('Wrong search category');
    }
    try {
        const response = await api.get(
            searchUrl(customerCode, category, filterValue),
            {},
            {
                headers: {
                    Accept: 'application/json',
                },
            },
        );
        return response.data.map((elm) => {
            elm.category = category === 'top' ? defineCategory(elm) : category;
            return elm;
        });
    } catch (error) {
        throw new Error(error.response.data);
    }
}

function defineCategory(searchResults) {
    if (searchResults.source.includes('monitoring')) {
        return 'monitoring';
    }
    return searchResults.source;
}

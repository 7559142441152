import { inject } from 'vue';

export const usePostHog = () => {
    const posthog = inject('posthog');
    return {
        capture: (event, properties) => {
            if (posthog) {
                posthog.capture(event, properties);
            }
        },
    };
};

import _ from 'lodash';

export const actionsTypes = {
    TOGGLE_FULLSCREEN: 'TOGGLE_FULLSCREEN',
};

/**
 * Get a VueX store or module, with fullscreen feature.
 *
 * To add a fullscreen mode in a module, simply call:
 *
 * ```js
 * import { addFullscreen } from '@/store/utils/fullscreen.utils';
 *
 * addFullscreen(myModule);
 * ```
 *
 * You can then use the `TOGGLE_FULLSCREEN` action to enable/disable the `fullscreen` query param:
 *
 * ```js
 * import { actionsTypes as fullscreenActionsTypes } from '@/store/utils/fullscreen.utils';
 *
 * ...mapActions(moduleNamespace, {
 *   toggleFullscreen: fullscreenActionsTypes.TOGGLE_FULLSCREEN,
 *  }),
 * ```
 *
 * @param {Object} store
 * @return {Object} Store with added feature
 */
export function addFullscreen(store) {
    return _.merge({}, store, {
        getters: {
            /**
             * Whether is the panel in fullscreen mode or not.
             *
             * @return {boolean} `true` if fullscreen enabled, else `false`
             */
            isFullscreen: (state, getters, rootState) =>
                String(_.get(rootState.route, 'query.fullscreen', false)).toLowerCase() === 'true',
        },
        actions: {
            /**
             * Toggle fullscreen.
             */
            [actionsTypes.TOGGLE_FULLSCREEN]({ getters }, { router }) {
                router.push({
                    query: _.merge({}, router.currentRoute.query, { fullscreen: !getters.isFullscreen }),
                });
            },
        },
    });
}

import { t } from 'i18next';
import _ from 'lodash';
import { arrayToTree } from 'performant-array-to-tree';
import { decimal, integer } from 'vuelidate/lib/validators';

const variablePlacehoderRegex = /{{[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}}}/gi;

/**
 * Check if a value is a valid decimal
 *
 * @param {string} value
 * @param {array} variables array of variables
 *
 * @returns {boolean} true if value is a decimal otherwise false
 */
export function isDecimal(value, variables) {
    if (!value) {
        return false;
    }
    const resolvedValue = resolveVariablesInText(value.toString(), variables);
    return resolvedValue !== '-0' && decimal(resolvedValue);
}

export function isInt(value, variables) {
    if (!value) {
        return false;
    }
    const resolvedValue = resolveVariablesInText(value.toString(), variables);
    return resolvedValue !== '-0' && integer(resolvedValue);
}

/**
 * Resolve the variable id by their value for formula
 *
 * @param {string} formula
 * @param {array} variables array of variable
 *
 * @returns {string}
 */
export function resolveVariablesInFormula(formula, variables = []) {
    if (!formula) {
        return formula;
    }
    return formula.replace(variablePlacehoderRegex, (match) => {
        const id = match.replace(/{|}/gi, '');
        const variable = variables.find((v) => v.id === id);
        if (!variable) {
            return match;
        } else if (variable.type === 'number') {
            return variable.value;
        } else if (variable.type === 'meter') {
            return `\${${variable.value}}`;
        } else if (variable.type === 'string') {
            return `"${variable.value}"`;
        }
        return variable.value;
    });
}

/**
 * Resolve the variable id by their value for text
 *
 * @param {string} text
 * @param {array} variables array of variable
 *
 * @returns {string}
 */
export function resolveVariablesInText(text, variables = []) {
    if (!text) {
        return text;
    }
    return text.replace(variablePlacehoderRegex, (match) => {
        const id = match.replace(/{|}/gi, '');
        const variable = variables.find((v) => v.id === id);
        return variable ? variable.value : match;
    });
}
/**
 * Replace the variable in a dashboard by variable's value
 * @param {*} variable
 * @param {*} dashboard
 * @returns {*} a dashboard copy without the ref of the variable
 */
export function replaceVariable(variable, dashboard) {
    const dashboardCopy = _.cloneDeep(dashboard);
    const variableRefs = getVariableRefs(variable, dashboardCopy);
    variableRefs.forEach((ref) => {
        if (/.content$/.test(ref)) {
            let content = _.get(dashboardCopy, ref);
            const variableBlotRegex = new RegExp(
                `<variable-blot data-binding="${variable.id}" data-type="variable"></variable-blot>`,
                'g',
            );
            content = content.replace(variableBlotRegex, variable.value);
            _.set(dashboardCopy, ref, content);
        } else if (/.formula$/.test(ref)) {
            const value = resolveVariablesInFormula(_.get(dashboardCopy, ref), [variable]);
            _.set(dashboardCopy, ref, value);
        } else if (/.variableId$/.test(ref)) {
            const datasetRef = ref.replace(/.variableId$/, '');
            const dataset = _.get(dashboardCopy, datasetRef);
            dataset.type = 'meter';
            dataset.meterId = variable.value;
            delete dataset.variableId;
            _.set(dashboardCopy, datasetRef, dataset);
        } else {
            const value = resolveVariablesInText(_.get(dashboardCopy, ref), [variable]);
            _.set(dashboardCopy, ref, value);
        }
    });
    return dashboardCopy;
}

/**
 * Export meter variable to perimeter for meter tree select
 *
 * @param {array} variables array of variable
 * @returns {object}
 */
export function variableToPerimeter(variables) {
    // add attributes for meter picker and filter to get only meter variable
    const dataVariables = variables
        .filter((v) => v.type === 'meter')
        .map((v) => {
            return {
                ...v,
                parentId: 'variables',
                type: 'variable',
            };
        });

    const variableParentElement = {
        id: 'variables',
        name: t('DASHBOARD_VARIABLES'),
        parentId: null,
        type: 'folder',
        icon: { name: 'code' },
    };
    dataVariables.push(variableParentElement);

    const variablesTree = arrayToTree(dataVariables, { dataField: null })[0];
    variablesTree.parentId = 'root';

    return variablesTree;
}

export function getIconByType(type) {
    return {
        meter: 'gauge',
        number: 'input-numeric',
        string: 'input-text',
    }[type];
}

/**
 * Get references where variable is referenced
 *
 * @param {object} variable variable
 * @param {object} dashboard a dashboard configuration
 * @returns {array}
 */
export function getVariableRefs(variable, dashboard) {
    const refs = [];
    if (dashboard.widgets) {
        dashboard.widgets.forEach((widget, index) => {
            const widgetType = widget.type;
            const path = `widgets[${index}]`;
            if ((widget.title ?? '').includes(variable.id)) {
                refs.push(`${path}.title`);
            }

            switch (widgetType) {
                case 'correlation-matrix':
                case 'proportion':
                case 'sankey':
                case 'statistics-table':
                case 'timeseries-table':
                    if (widget.data?.series) {
                        widget.data.series.forEach((serie, indexSerie) => {
                            refs.push(
                                ...getVariableRefsInSerie(variable.id, serie, `${path}.data.series[${indexSerie}]`),
                            );
                        });
                    }
                    break;
                case 'statistics-chart':
                case 'timeseries':
                case 'histogram':
                case 'scatter':
                case 'waterfall-chart':
                    if (widget.data) {
                        Object.entries(widget.data).forEach(([key, value]) => {
                            if (!['xAxis', 'yAxis', 'y2Axis', 'zAxis'].includes(key)) {
                                return;
                            }
                            refs.push(...getVariableRefsInAxe(variable.id, value, `${path}.data.${key}`));
                        });
                    }
                    break;
                case 'heatmap':
                case 'speedometer':
                case 'statistic':
                    if (widget.data) {
                        refs.push(...getVariableRefsInSerie(variable.id, widget.data, `${path}.data`));
                    }
                    break;
                case 'text':
                    if (widget.data?.content) {
                        if (widget.data.content.includes(variable.id)) {
                            refs.push(`${path}.data.content`);
                        }
                    }
                    if (widget.data?.series) {
                        widget.data.series.forEach((serie, indexSerie) => {
                            refs.push(
                                ...getVariableRefsInSerie(variable.id, serie, `${path}.data.series[${indexSerie}]`),
                            );
                        });
                    }
                    break;
                case 'default':
                    return;
            }
        });
    }
    return refs;
}

function getVariableRefsInAxe(variableId, axe, path) {
    const refs = [];
    const commonProperties = ['label', 'min', 'max', 'nbBuckets'].reduce((propertyRefs, property) => {
        if (axe[property] && axe[property].includes(variableId)) {
            return [...propertyRefs, `${path}.${property}`];
        }
        return propertyRefs;
    }, []);
    refs.push(...commonProperties);

    if (axe.series) {
        axe.series.forEach((serie, index) => {
            refs.push(...getVariableRefsInSerie(variableId, serie, `${path}.series[${index}]`));
        });
    } else {
        refs.push(...getVariableRefsInSerie(variableId, axe, path));
    }
    return refs;
}

function getVariableRefsInSerie(variableId, serie, path) {
    const refs = [];
    if ((serie.title ?? '').includes(variableId)) {
        refs.push(`${path}.title`);
    }
    if (serie.type === 'variable' && serie.variableId === variableId) {
        refs.push(`${path}.variableId`);
    } else if (serie.type === 'formula' && serie.formula.includes(variableId)) {
        refs.push(`${path}.formula`);
    }
    return refs;
}

export const GO_TO_DASHBOARDS = 'GO_TO_DASHBOARDS';
export const GO_TO_CREATE_DASHBOARD = 'GO_TO_CREATE_DASHBOARD';
export const CREATE_FOLDER = 'CREATE_FOLDER';
export const UPDATE_FOLDER = 'UPDATE_FOLDER';
export const DELETE_FOLDER = 'DELETE_FOLDER';
export const GO_TO_CONFIGURE_WIDGET = 'GO_TO_CONFIGURE_WIDGET';
export const GO_TO_ITEM = 'GO_TO_ITEM';
export const UPDATE_DASHBOARD = 'UPDATE_DASHBOARD';
export const MOVE_DASHBOARD = 'MOVE_DASHBOARD';
export const MOVE_DASHBOARD_OR_FOLDER_TO_PARENT = 'MOVE_DASHBOARD_OR_FOLDER_TO_PARENT';
export const DELETE_DASHBOARD = 'DELETE_DASHBOARD';
export const GO_TO_CREATE_WIDGET = 'GO_TO_CREATE_WIDGET';
export const CREATE_WIDGET = 'CREATE_WIDGET';
export const DELETE_WIDGET = 'DELETE_WIDGET';
export const UPDATE_WIDGET = 'UPDATE_WIDGET';
export const GO_TO_WIDGET_COMMENTS = 'GO_TO_WIDGET_COMMENTS';

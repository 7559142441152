<template>
    <div v-if="zendeskIsConfigured" @click.prevent="onClick">
        <a v-if="text" class="pointer help-button">{{ text }}</a>
        <UiIconButton
            v-else
            class="helpButton"
            icon-name="far circle-question"
            size="medium"
            color="white"
            appearance="flat"
            data-test="help-button"
        ></UiIconButton>
    </div>
</template>
<script>
import i18next from 'i18next';
import { mapActions, mapState } from 'vuex';
import { NAMESPACE as NS_USER } from '@/store/modules/users';
import { FETCH_CURRENT_USER as fetchCurrentUser } from '@/store/modules/users/action-types';
import { UiIconButton, colors } from '@energiency/ui';

export default {
    components: {
        UiIconButton,
    },
    props: {
        /**
         * Handle the simple link aspect
         */
        text: {
            type: String,
            default: null,
        },
    },
    computed: {
        ...mapState(NS_USER, ['currentUser']),
        zendeskIsConfigured() {
            return process.env.ENABLE_TRACKING_SCRIPTS !== 'false';
        },
    },
    watch: {
        currentUser: {
            immediate: true,
            handler(newUser) {
                if (!this.zendeskIsConfigured || !newUser) {
                    return;
                }
                const name = `${newUser.firstName} ${newUser.lastName}`;
                const email = newUser.email;
                const zendeskSettings = {
                    webWidget: {
                        position: { horizontal: 'right', vertical: 'top' },
                        color: { theme: colors.primary },
                        offset: {
                            vertical: '50px',
                            horizontal: '15px',
                        },
                    },
                };
                if (newUser.zendeskToken) {
                    zendeskSettings.authenticate = { jwt: newUser.zendeskToken };
                }

                window.zESettings = zendeskSettings;

                window.zE(() => {
                    window.zE.setLocale(i18next.language);
                    window.zE.identify({ name, email });
                });
            },
        },
    },
    methods: {
        ...mapActions(NS_USER, {
            fetchCurrentUser,
        }),
        onClick() {
            const firstURLTerm = document.location.pathname.split('/')[2];
            if (firstURLTerm) {
                window.zE.setHelpCenterSuggestions({ search: firstURLTerm }); // eslint-disable-line no-undef
            }
            setTimeout(() => {
                window.zE.activate();
            });
        },
    },
};
</script>

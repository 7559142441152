import { default as i18next, t } from 'i18next';
import {
    createDataType,
    deleteDataType,
    getDataType,
    getDataTypeReferences,
    getDataTypes,
    updateDataType,
} from '@/api/data-types.service';
import { localize } from '@/helpers/locales';
import { notify } from '@/helpers/notifications';
import dataModule, { actionTypes as dataActionTypes } from '@/store/reusable-modules/data-list.module';
import { addEdition } from '@/store/utils/edition.utils';
import { confirmModal } from '@energiency/ui';
import {
    DELETE_DATA_TYPE,
    GO_TO_CREATE_DATA_TYPE,
    GO_TO_DATA_TYPE,
    GO_TO_DATA_TYPES,
    UPDATE_DATA_TYPE,
} from './action-types';
import { UPDATE_DATA_TYPE_SUCCESS } from './mutation-types';

export const NAMESPACE = 'dataTypes';

export const getters = {
    isCreatingDataType: (state) => state.dataTypes.pending.create,
    selectedItemId: (state, _getters, rootState) => {
        return rootState?.route?.params?.id;
    },
    selectedDataType: (state) => {
        return state.dataTypes.item;
    },
    getUnits: (state) => (dataTypeId) => {
        const dataType = state.dataTypes.items.find((item) => item.id === dataTypeId);
        return dataType ? dataType.units : [];
    },
};

export const mutations = {
    /**
     * @param {*} state
     * @param {*} dataType
     */
    [UPDATE_DATA_TYPE_SUCCESS](state, dataType) {
        const index = state.dataTypes.items.findIndex(({ id }) => id === dataType.id);
        if (index >= 0) {
            state.dataTypes.items.splice(index, 1, localize(dataType));
        }
        state.dataTypes.item = dataType;
    },
};

export const actions = {
    /**
     * Navigate to a data type
     * @param {*} context
     */
    [GO_TO_DATA_TYPE](context, { id, router }) {
        router.push({
            name: 'data-type-infos',
            params: { id },
            query: router.currentRoute.query,
        });
    },
    /**
     * Navigate to data types list.
     *
     * @param {Object} context
     */
    [GO_TO_DATA_TYPES](context, { router }) {
        router.push({ name: 'data-types' });
    },

    /**
     * Navigate to data type creation form
     * @param {Object} context
     */
    [GO_TO_CREATE_DATA_TYPE](context, { router }) {
        router.push({ name: 'data-types-new' });
    },

    /**
     * Update data type
     *  @param {Object} context
     */
    async [UPDATE_DATA_TYPE]({ commit, rootGetters }, dataType) {
        try {
            const updatedDataType = await updateDataType({
                customerCode: rootGetters.customerCode,
                dataType,
            });
            commit(UPDATE_DATA_TYPE_SUCCESS, updatedDataType);
            notify({
                type: 'success',
                text: t('UPDATE_DATA_TYPE_SUCCESS'),
            });
        } catch (error) {
            notify({
                type: 'error',
                text: t('UPDATE_DATA_TYPE_ERROR'),
            });
            throw error;
        }
    },
    async [DELETE_DATA_TYPE]({ dispatch, rootGetters }, { item, router }) {
        try {
            const { data, total } = await getDataTypeReferences({
                customerCode: rootGetters.customerCode,
                id: item.id,
            });
            if (total > 0) {
                const subtitle = `<h4>${t('DATA_TYPE_REFS_SUBTITLE', { total })}<h4><br>`;
                const refsText = data
                    .map(({ id, name }) => {
                        const link = router.resolve({ name: 'meter-infos', params: { id } }).href;
                        return `<a href="${link}">${name}</a>`;
                    })
                    .join('<br>');
                const more = total > data.length ? `<br>${t('MORE_TEXT')}` : '';
                const html = `${subtitle}${refsText}${more}`;
                return confirmModal({
                    showConfirmButton: false,
                    labels: { cancelButtonText: t('CANCEL_TEXT'), title: t('DATA_TYPE_REFS_TITLE'), html },
                });
            }
            return dispatch(dataActionTypes.DELETE_ITEM, { item, router });
        } catch (error) {
            notify({ type: 'error', text: t('DELETE_DATA_TYPE_ERROR') });
            throw error;
        }
    },
};

export default addEdition(
    {
        namespaced: true,
        getters,
        actions,
        mutations,
        modules: {
            dataTypes: dataModule({
                namespaced: false,
                async getItem({ rootGetters }, id, { lang }) {
                    const dataType = await getDataType({ customerCode: rootGetters.customerCode, id, lang });
                    return dataType;
                },
                async getItems({ rootGetters }, { fields }) {
                    return getDataTypes({ customerCode: rootGetters.customerCode, fields, lang: i18next.language });
                },
                async createItem({ dispatch, rootGetters }, { item, router }) {
                    try {
                        const createdDataType = await createDataType({
                            customerCode: rootGetters.customerCode,
                            dataType: item,
                        });
                        notify({ type: 'success', text: t('CREATE_DATA_TYPE_SUCCESS') });
                        dispatch(GO_TO_DATA_TYPE, { id: createdDataType.id, router });
                        return localize(createdDataType);
                    } catch (error) {
                        notify({ type: 'error', text: t('CREATE_DATA_TYPE_ERROR') });
                        throw error;
                    }
                },
                async deleteItem({ dispatch, rootGetters }, { item, router }) {
                    try {
                        await deleteDataType({
                            customerCode: rootGetters.customerCode,
                            id: item.id,
                        });
                        notify({ type: 'success', text: t('DELETE_DATA_TYPE_SUCCESS') });
                        dispatch(`${NAMESPACE}/${GO_TO_DATA_TYPES}`, { router }, { root: true });
                    } catch (error) {
                        notify({ type: 'error', text: t('DELETE_DATA_TYPE_ERROR') });
                        throw error;
                    }
                },
            }),
        },
    },
    {
        saveFunction: actions[UPDATE_DATA_TYPE],
    },
);

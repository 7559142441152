import { differenceInMilliseconds } from 'date-fns';
import { parse, toMilliseconds } from 'duration-fns';

const MAX_POINTS = 2000; // allow to display chart with a date range of a year with an hourly timestep
const IDEAL_POINTS = 100;

/**
 * Set a `disabled` attribute on given timesteps.
 *
 * @param {Date|string} startDate An ISO 8061 string or Date
 * @param {Date|string} endDate An ISO 8061 string or Date
 * @param {Array} timesteps List of timesteps
 * @param {number} maxPoints Max number of point to display by timestep
 * @return {Array} List of timesteps with `disabled` attribute
 */
export function getDisabledTimesteps(startDate, endDate, timesteps = [], maxPoints = MAX_POINTS) {
    const maxTimestepMilliseconds = diff(startDate, endDate) / maxPoints;
    return timesteps.map((ts) => ({
        value: ts,
        disabled: toMilliseconds(parse(ts)) < maxTimestepMilliseconds,
    }));
}

/**
 * Get enabled timesteps
 *
 * @param {Date|string} startDate An ISO 8061 string or Date
 * @param {Date|string} endDate An ISO 8061 string or Date
 * @param {Array<string>}   timesteps List of timestep as ISO 8601 string
 * @return {Array} The enabled timesteps
 */
function getEnabledTimesteps(startDate, endDate, timesteps) {
    return getDisabledTimesteps(startDate, endDate, timesteps)
        .filter((ts) => !ts.disabled)
        .map(({ value }) => value);
}
/**
 *
 * @param {Date|string} startDate An ISO 8061 string or Date
 * @param {Date|string} endDate An ISO 8061 string or Date
 * @return {number} difference in milliseconds
 */
function diff(startDate, endDate) {
    const dateRight = typeof startDate === 'string' ? new Date(startDate) : startDate;
    const dateLeft = typeof endDate === 'string' ? new Date(endDate) : endDate;
    return differenceInMilliseconds(dateLeft, dateRight);
}

/**
 * Indicate wheter the timestep is allowed for the given date range
 *
 * @param {string}          timestep Timestep as ISO 8601 string
 * @param {Date|string}     startDate An ISO 8061 string or Date
 * @param {Date|string}     endDate An ISO 8061 string or Date
 * @param {Array<string>}   timesteps List of timestep as ISO 8601 string
 * @return {Boolean}  true if timestep allowed false otherwise
 */
export function isTimestepAllowed(timestep, startDate, endDate, timesteps) {
    const enabledTimeSteps = getEnabledTimesteps(startDate, endDate, timesteps);
    return timestep && !!enabledTimeSteps.find((ts) => ts === timestep);
}

/**
 * Get the most pertinent timestep according to the given date range
 *
 * @param {Date|string} startDate An ISO 8061 string or Date
 * @param {Date|string} endDate An ISO 8061 string or Date
 * @param {Array<string>}     timesteps List of timestep as ISO 8601 string
 * @return {string} The ideal time timestep
 */
export function getIdealTimestep(startDate, endDate, timesteps = []) {
    const enabledTimesteps = getEnabledTimesteps(startDate, endDate, timesteps);
    const idealTimestepMilliseconds = diff(startDate, endDate) / IDEAL_POINTS;
    const availableTimesteps = enabledTimesteps.filter((ts) => toMilliseconds(parse(ts)) <= idealTimestepMilliseconds);
    const ts =
        availableTimesteps.length > 0
            ? availableTimesteps[availableTimesteps.length - 1]
            : enabledTimesteps.length > 0
            ? enabledTimesteps[0]
            : undefined;
    return ts ? ts : 'PT1H';
}

import { t } from 'i18next';
import moment from 'moment';
import { getSettings, updateSettings } from '@/api/settings.service';
import { getDisabledTimesteps, getIdealTimestep, isTimestepAllowed } from '@/utils/timestep.utils';
import { notify } from '@/helpers/notifications';
import { addEdition } from '@/store/utils/edition.utils';
import { FETCH_SETTINGS } from './action-types';
import {
    FETCHING_SETTINGS,
    FETCH_SETTINGS_ERROR,
    FETCH_SETTINGS_SUCCESS,
    SAVE_SETTINGS_SUCCESS,
} from './mutation-types';

/** @typedef { import('@/api/settings.service.js').Settings }  */

/**
 * @typedef {Object} SettingsState
 * @property {boolean} isFetching Indicates whether the settings are being fetched
 * @property {Settings} settings Settings
 */

/** @type {SettingsState} */
const _state = {
    isFetching: false,
    isUpdating: false,
    settings: null,
};

export const mutations = {
    /**
     * @param {SettingsState} state
     */
    [FETCHING_SETTINGS](state) {
        state.isFetching = true;
    },
    /**
     * @param {SettingsState} state
     * @param {Settings} settings
     */
    [FETCH_SETTINGS_SUCCESS](state, settings) {
        state.settings = settings;
        state.isFetching = false;
    },
    /**
     * @param {SettingsState} state
     */
    [FETCH_SETTINGS_ERROR](state) {
        state.isFetching = false;
    },
    /**
     * @param {SettingsState} state
     * @param {Settings} settings
     */
    [SAVE_SETTINGS_SUCCESS](state, settings) {
        state.settings = settings;
    },
};

export const getters = {
    /**
     * Return the name of the customer
     *
     * @param {SettingsState} state
     * @return {string} CustomerName
     */
    customerName: (state) => (state.settings ? state.settings.name : ''),
    /**
     * Return feature flags.
     *
     * @param {SettingsState} state
     * @return {Object} Features
     */
    features: (state) => (state.settings ? state.settings.features ?? {} : null),
    /**
     * Return timezone.
     *
     * @param {SettingState} state
     * @return {String} timezone
     */
    timezone: (state) => state.settings?.timezone ?? moment.guess(),
    /**
     * Return currency.
     *
     * @param {SettingState} state
     * @return {String} currency
     */
    currency: (state) => state.settings?.currency ?? '€',
    /**
     * Return kpis.
     *
     * @param {SettingState} state
     * @return {Array} Kpis
     */
    kpis: (state) => state.settings?.kpis ?? [],
    /**
     * Return timesteps.
     *
     * @param {SettingState} state
     * @return {Array<string>} Array of timestep as ISO 8061 string
     */
    timesteps: (state) => state.settings?.timesteps ?? [],
    /**
     * Return appearance.
     *
     * @param {SettingState} state
     * @return {Array<string>} Object of appearance configuration
     */
    appearance: (state) => state.settings?.appearance ?? {},
    /**
     * Return logo.
     *
     * @param {SettingState} state
     * @return {string} Logo path url
     */
    logoUrl: (state) => `${state.settings?.appearance?.logoUrl ?? ''}?t=${new Date(Date.now()).toISOString()}`,
    /**
     * Return timesteps with disabled property
     * @param {SettingsState} state
     * @param {SettingsGetters} _getters
     * @param {Date|string} startDate An ISO 8061 string or Date
     * @param {Date|string} endDate An ISO 8061 string or Date
     * @param {number} maxPoints Max number of point to display by timestep
     * @returns {Array}   Array of timestep value with disabled propety
     */
    getDisabledTimesteps: (state, _getters) => (startDate, endDate, maxPoints) => {
        return getDisabledTimesteps(startDate, endDate, _getters.timesteps, maxPoints);
    },
    /**
     * Return ideal timestep for a given date range
     *
     * @param {SettingsState} state
     * @param {SettingsGetters} _getters
     * @param {Date|string} startDate An ISO 8061 string or Date
     * @param {Date|string} endDate An ISO 8061 string or Date
     * @returns {string} Timestep as ISO 8601 string
     */
    getIdealTimestep: (state, _getters) => (startDate, endDate) => {
        return getIdealTimestep(startDate, endDate, _getters.timesteps);
    },
    /**
     * Return if a timestep is allowed for a given date range
     * @param {SettingsState} state
     * @param {SettingsGetters} _getters
     * @param {Date|string} startDate An ISO 8061 string or Date
     * @param {Date|string} endDate An ISO 8061 string or Date
     * @returns {Boolean} true if timestep allowed false otherwise
     */
    isTimestepAllowed: (state, _getters) => (timestep, startDate, endDate) => {
        return isTimestepAllowed(timestep, startDate, endDate, _getters.timesteps);
    },
};

let getSettingsPromise = null;

export const actions = {
    /**
     * Fetch settings.
     *
     * @param {Object} context
     */
    async [FETCH_SETTINGS]({ commit, rootGetters }, { shareLinkId, dashboardId }) {
        commit(FETCHING_SETTINGS);
        try {
            if (!getSettingsPromise) {
                getSettingsPromise = getSettings({ customerCode: rootGetters.customerCode, shareLinkId, dashboardId });
            }
            const settings = await getSettingsPromise;
            getSettingsPromise = null;
            commit(FETCH_SETTINGS_SUCCESS, settings);
        } catch (error) {
            commit(FETCH_SETTINGS_ERROR);
        }
    },
};

export const NAMESPACE = 'settings';

export default addEdition(
    {
        namespaced: true,
        state: _state,
        actions,
        mutations,
        getters,
    },
    {
        /**
         * Update settings.
         *
         * @param {Object} context
         * @param {Settings} settings Settings to save
         */
        async saveFunction({ commit, rootGetters }, settings) {
            try {
                const updatedSettings = await updateSettings({
                    customerCode: rootGetters.customerCode,
                    settings,
                });
                commit(SAVE_SETTINGS_SUCCESS, updatedSettings);
            } catch (error) {
                notify({
                    type: 'error',
                    text: t('UPDATE_SETTINGS_ERROR'),
                });
            }
        },
    },
);

import { t } from 'i18next';
import { keyBy } from 'lodash';
import { arrayToTree } from 'performant-array-to-tree';
import { createFolder, deleteFolder, getFolders, updateFolder } from '@/api/folders.service';
import { notify } from '@/helpers/notifications';
import dataModule from '@/store/reusable-modules/data-list.module';
import {
    actionTypes as documentActionType,
    default as documentsModule,
} from '@/store/reusable-modules/documents.module';
import { addEdition } from '@/store/utils/edition.utils';
import { CREATE_FOLDER, GO_TO_DOCUMENTS, MOVE_DOCUMENT_OR_FOLDER_TO_PARENT, UPDATE_FOLDER } from './action-types';
import {
    CREATE_FOLDER_ERROR,
    CREATE_FOLDER_SUCCESS,
    CREATING_FOLDER,
    DELETE_FOLDER_SUCCESS,
    SET_LAST_EXPANDED_ELEMENT_ID,
    UPDATE_FOLDER_SUCCESS,
} from './mutation-types';

export const NAMESPACE = 'documents';

const _state = {
    isCreatingFolder: false,
    lastExpandedElementId: null,
};

export const getters = {
    folderById: (state) => {
        return keyBy(state.folders?.items ?? [], 'id');
    },
    /**
     * Return the documents tree.
     * @param {ActionsState} state
     * @param {ActionsGetters} _getters
     * @returns {object}
     */
    documentsTree: (state, _getters) => {
        const tree = arrayToTree(_getters.documentsAndFolders, { dataField: null });
        return {
            id: 'root',
            children: tree.map((rootItem) => ({
                ...rootItem,
                parentId: 'root',
            })),
        };
    },
    documentsAndFolders: (state) => {
        const folders = state.folders?.items ?? [];
        const documents = state.documents.documents?.items ?? [];
        const unattachedDocuments = documents.filter((element) => !element.subjectId);

        return [
            ...folders.map((folder) => ({
                type: 'folder',
                icon: { name: 'folder' },
                ...folder,
            })),
            ...unattachedDocuments.map((document) => ({ type: 'document', ...document })),
        ];
    },
    documentsAndFoldersById: (state, _getters) => {
        return keyBy(_getters.documentsAndFolders, 'id');
    },
};

export const mutations = {
    /**
     * Save last expanded element id.
     *
     * @param {DocumentState} state
     * @param {string}      expandedElementId
     */
    [SET_LAST_EXPANDED_ELEMENT_ID](state, expandedElementId) {
        state.lastExpandedElementId = expandedElementId;
    },
    /**
     * @param {DocumentState} state
     */
    [CREATING_FOLDER](state) {
        state.isCreatingFolder = true;
    },
    /**
     * @param {DocumentState} state
     * @param {*} folder
     */
    [CREATE_FOLDER_SUCCESS](state, folder) {
        state.folders.items = [...state.folders.items, folder];
        state.isCreatingFolder = false;
    },
    /**
     * @param {DocumentState} state
     */
    [CREATE_FOLDER_ERROR](state) {
        state.isCreatingFolder = false;
    },
    /**
     * @param {DocumentState} state
     * @param {*} updatedFolder
     */
    [UPDATE_FOLDER_SUCCESS](state, updatedFolder) {
        const folders = state?.folders?.items ?? [];
        if (folders) {
            const index = folders.findIndex(({ id }) => id === updatedFolder.id);
            folders.splice(index, 1, updatedFolder);
        }
    },
    /**
     * @param {DocumentState} state
     * @param {*} deletedFolder
     */
    [DELETE_FOLDER_SUCCESS](state, deletedFolder) {
        const folders = state?.folders?.items ?? [];
        if (folders) {
            const index = folders.findIndex(({ id }) => id === deletedFolder.id);
            folders.splice(index, 1);
        }
    },
};

export const actions = {
    async [CREATE_FOLDER]({ commit, dispatch, rootGetters }, { folder, router }) {
        commit(CREATING_FOLDER);
        try {
            const createdFolder = await createFolder({
                customerCode: rootGetters.customerCode,
                folder: {
                    ...folder,
                    context: 'documents',
                },
            });
            notify({
                type: 'success',
                text: t('CREATE_FOLDER_SUCCESS'),
            });
            commit(CREATE_FOLDER_SUCCESS, createdFolder);
            dispatch(GO_TO_DOCUMENTS, {
                folderId: createdFolder.id,
                router,
            });
        } catch (error) {
            notify({
                type: 'error',
                text: t('CREATE_FOLDER_ERROR'),
            });
            commit(CREATE_FOLDER_ERROR);
        }
    },
    /**
     * Navigate to documents list
     *
     * @param {Object} context
     * @param {Object} payload
     */
    [GO_TO_DOCUMENTS](context, { router, folderId }) {
        if (folderId) {
            router.push({
                name: 'document-folder-panel',
                query: {
                    view: 'tree',
                },
                params: {
                    id: folderId,
                },
            });
        } else {
            router.push({
                name: 'customer.documents',
                query: router.currentRoute.query,
            });
        }
    },
    async [UPDATE_FOLDER]({ commit, rootGetters }, folder) {
        if (!folder.description) {
            folder.description = '';
        }
        try {
            const updatedFolder = await updateFolder({
                customerCode: rootGetters.customerCode,
                folder,
            });
            commit(UPDATE_FOLDER_SUCCESS, updatedFolder);
        } catch (error) {
            notify({
                type: 'error',
                text: t('UPDATE_FOLDER_ERROR'),
            });
            throw new Error(error.response.data);
        }
    },
    async [MOVE_DOCUMENT_OR_FOLDER_TO_PARENT]({ commit, dispatch, rootGetters, getters: _getters }, { moved, to }) {
        const movedItem = _getters.documentsAndFoldersById[moved];
        const toItem = to === 'root' ? { id: null } : _getters.documentsAndFoldersById[to];

        if (movedItem && toItem) {
            try {
                const updatedItem = {
                    ...movedItem,
                    parentId: toItem.id,
                };

                if (movedItem.type === 'folder') {
                    await updateFolder({
                        customerCode: rootGetters.customerCode,
                        folder: updatedItem,
                    });
                    commit(UPDATE_FOLDER_SUCCESS, updatedItem);
                } else {
                    dispatch(`${NAMESPACE}/${documentActionType.UPDATE_DOCUMENT}`, updatedItem, {
                        root: true,
                    });
                }
            } catch (error) {
                notify({
                    type: 'error',
                    text: t('ITEM_MOVE_ERROR'),
                });
            }
        }
    },
};

export default {
    namespaced: true,
    state: _state,
    getters,
    mutations,
    actions,
    modules: {
        documents: documentsModule(),
        folders: addEdition(
            dataModule({
                namespaced: true,
                async getItems({ rootGetters }, { fields }) {
                    return await getFolders({
                        customerCode: rootGetters.customerCode,
                        fields,
                        filters: { context: 'documents' },
                    });
                },
                async deleteItem({ commit, dispatch, rootGetters }, { item, router }) {
                    try {
                        await deleteFolder({
                            customerCode: rootGetters.customerCode,
                            id: item.id,
                        });
                        notify({
                            type: 'success',
                            text: t('DELETE_FOLDER_SUCCESS'),
                        });

                        commit(`${NAMESPACE}/${DELETE_FOLDER_SUCCESS}`, item, { root: true });
                        dispatch(`${NAMESPACE}/${GO_TO_DOCUMENTS}`, { router, folder: item.parentId }, { root: true });
                    } catch (error) {
                        notify({
                            type: 'error',
                            text: t('DELETE_FOLDER_ERROR'),
                        });
                        throw error;
                    }
                },
            }),
            {
                async saveFunction({ dispatch }, folder) {
                    dispatch(`${NAMESPACE}/${UPDATE_FOLDER}`, folder, { root: true });
                },
            },
        ),
    },
};

import api from './api.config';

/**
 * @typedef {Object} Point
 * @property {string} meterId            id of the meter
 * @property {number|string} quantity
 * @property {string} date
 */

/**
 * Return the date range URL.
 *
 * @param {string} customerCode Customer code
 * @param {string} meterId Meter ID
 * @return {string} URL
 */
function dateRangeUrl(customerCode, meterId) {
    return `/timeseries/v2/${customerCode}/meters/${meterId}/date-range`;
}

/**
 * Retrieve the data date range of a meter from the REST API.
 *
 * @param {Object}  params
 * @param {string}  params.customerCode Customer code
 * @param {string}  params.meterId      ID of the meter
 * @return {Object} date range
 */
export async function getDateRange({ customerCode, meterId }) {
    try {
        const response = await api.get(`${dateRangeUrl(customerCode, meterId)}`, {
            headers: {
                Accept: 'application/json',
            },
        });

        const { firstDate, lastDate } = response.data;
        if (firstDate && lastDate) {
            return {
                firstDate: new Date(firstDate),
                lastDate: new Date(lastDate),
            };
        }

        return null;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Return the points resource URL.
 *
 * @param {string} customerCode Customer code
 * @return {string} URL
 */
function timeseriesUrl(customerCode) {
    return `/timeseries/v2/${customerCode}/timeseries`;
}

/**
 * Retrieve points data from the REST API.
 *
 * @param {Object} params
 * @param {Object} params.filters       List of filters
 * @param {string} params.format        Format ("json" or "csv")
 * @param {string} params.lang          Language to use for column headers
 * @param {string} params.timezone      Timezone of the data
 * @return {Promise<Array<Point>>}      Timeseries data
 */
export async function getPoints({ customerCode, filters, format = 'json', lang = 'en', timezone }) {
    try {
        const response = await api.get(timeseriesUrl(customerCode), {
            headers: {
                Accept: format === 'csv' ? 'text/csv' : 'application/json',
                'Accept-Language': lang,
            },
            params: { ...filters, timezone },
        });

        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Upsert points
 *
 * @param {Object}  params
 * @param {string}  params.customerCode Customer code
 * @param {Point[]} params.points      Point data
 * @return {Promise<Point[]>} Points
 */
export async function upsertPoints({ customerCode, points }) {
    try {
        const response = await api.put(timeseriesUrl(customerCode), points);
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Delete points
 *
 * @param {Object}  params
 * @param {string}  params.customerCode Customer code
 * @param {Point[]} params.points      Point data
 * @return {Promise<Point[]>} Points
 */
export async function deletePoints({ customerCode, points }) {
    try {
        const response = await api.delete(timeseriesUrl(customerCode), {
            headers: { Accept: 'application/json' },
            data: points,
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

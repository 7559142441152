<template>
    <div class="ErrorPage flex h-100 w-100">
        <div class="flex flex-column justify-between pa4 h-100 w-60">
            <div class="flex tc logo">
                <router-link :to="dashboardsRoute">
                    <img src="/images/logo-white-full.png" alt="Energiency" />
                </router-link>
            </div>
            <div class="pb4 white">
                <h1 class="f-6 fw6">
                    {{ $t('OOPS_TEXT') }}
                </h1>
                <div class="bar mt4 mb3 bg-white" />
                <h2 class="power f2">
                    {{ $t('ERROR_PAGE_CATCHPHRASE_TEXT') }}
                </h2>
                <div class="way f4">
                    {{ $t('ERROR_PAGE_REDIRECT_TEXT') }}
                </div>
                <UiButton class="mt3" @click="returnBack">
                    {{ $t('GO_BACK_TEXT') }}
                </UiButton>
            </div>
            <div class="flex">
                <div v-for="item in links" :key="item.name" class="f4">
                    <router-link class="pr4 white" :to="item.to">
                        {{ item.name }}
                    </router-link>
                </div>
            </div>
        </div>
        <div class="cloud flex h-100 w-40 justify-end items-end pr5 text-primary">
            <span class="fw6 o-50 relative">404</span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { UiButton } from '@energiency/ui';

export default {
    components: {
        UiButton,
    },
    computed: {
        ...mapGetters(['customerCode']),
    },
    created() {
        this.dashboardsRoute = { name: 'customer.dashboards', params: { customerCode: this.customerCode } };
        this.links = [
            {
                to: this.dashboardsRoute,
                name: this.$t('DASHBOARDS_TITLE'),
            },
            {
                to: { name: 'customer.reports', params: { customerCode: this.customerCode } },
                name: this.$t('REPORTS_TITLE'),
            },
            {
                to: { name: 'customer.actions', params: { customerCode: this.customerCode } },
                name: this.$t('ACTIONS_TEXT'),
            },
            {
                to: { name: 'customer.alerts', params: { customerCode: this.customerCode } },
                name: this.$t('ALERTS_TITLE'),
            },
            {
                to: { name: 'customer.meters', params: { customerCode: this.customerCode } },
                name: this.$t('METERS_TITLE'),
            },
        ];
    },
    methods: {
        returnBack() {
            window.history.back();
        },
    },
};
</script>

<style lang="less">
@import (reference) '~@energiency/ui/src/style/colors';
.ErrorPage {
    background: linear-gradient(
        154.39deg,
        #006dbc 18.67%,
        rgba(0, 158, 227, 0.36) 87.12%,
        rgba(0, 159, 227, 0.015625) 87.12%,
        rgba(0, 159, 227, 0.36) 87.13%
    );
    .logo {
        max-width: 250px;
    }
    .bar {
        width: 80px;
        height: 4px;
    }
    .cloud {
        position: relative;
        font-size: 11vw;
        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            display: block;
            width: 100%;
            height: 100%;
            background-image: url('../../../assets/images/cloud.svg');
            background-repeat: no-repeat;
            background-position: bottom right;
            background-size: contain;
        }
    }
    a {
        &:hover {
            color: white;
            text-decoration: underline;
        }
    }
}
</style>

import config from '@/config/config.json';
import { changeLanguage } from 'i18next';
import moment from 'moment';

export const flags = {
    fr: '🇫🇷',
    es: '🇪🇸',
    it: '🇮🇹',
    pt: '🇵🇹',
    de: '🇩🇪',
    en: '🇬🇧',
};

export const availableLanguages = config.availableLanguages;
export const supportedLanguages = config.supportedLanguages;
export const defaultLanguage = config.defaultLanguage;
export const i18nStrings = config.availableLanguages.reduce((strings, locale) => {
    try {
        strings[locale] = {
            translation: require(`@energiency/locales/i18n/locale_${locale}.json`),
        };
    } catch (error) {
        const lang = locale.split('-')[0];
        if (!strings[lang]) {
            strings[lang] = {
                translation: require(`@energiency/locales/i18n/locale_${lang}.json`),
            };
        }
    }
    return strings;
}, {});

export function getCleanLanguage(language) {
    if (availableLanguages.includes(language)) {
        // Case 1: we know the language (ex: fr)
        return language;
    }
    const [subLanguage] = language.split('-');
    if (availableLanguages.includes(subLanguage)) {
        // Case 2: we know a close language (ex: fr for fr-FR)
        return subLanguage;
    }
    const lang = availableLanguages.find((l) => l.match(new RegExp(`^${subLanguage}-`)));
    if (lang) {
        // Case 3: we know another variant of the language (ex: pt-BR for pt or pt-XX)
        return lang;
    }
    // By default, we return the default language
    return defaultLanguage;
}

export function updateAppWithUserLocale(user) {
    let language = window.navigator.userLanguage || window.navigator.language;
    if (user.language && user.language !== 'automatic') {
        language = user.language;
    }
    language = getCleanLanguage(language);
    moment.locale(language);
    changeLanguage(language);
}

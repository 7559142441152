import { hasRessourceAccess } from '@/router/navigation-guards.utils';
import { getStore } from '@/store';
import { NAMESPACE as NS_SETTINGS } from '@/store/modules/settings';

const Home = () => import(/* webpackChunkName: "home" */ '@/pages/Home.vue');
const HomeConfigurationPanel = () =>
    import(/* webpackChunkName: "home" */ '@/components/home/HomeConfigurationPanel.vue');

export default {
    name: 'customer.home',
    path: '',
    meta: {
        title: 'HOME_TITLE',
    },
    component: Home,
    beforeEnter: (to, from, next) => {
        const store = getStore();
        if (!store.getters[`${NS_SETTINGS}/features`]?.enableHomePage) {
            next({ name: 'customer.dashboards', params: { customerCode: to.params.customerCode } });
        } else {
            next();
        }
    },
    children: [
        {
            name: 'home-configuration',
            path: 'home/configuration',
            components: {
                default: Home,
                layer: HomeConfigurationPanel,
            },
            beforeEnter: async (to, from, next) => {
                if (await hasRessourceAccess('update', 'Settings', null, 'kpis')) {
                    next();
                } else {
                    next({ name: 'home', params: { customerCode: to.params.customerCode } });
                }
            },
        },
        {
            name: 'home-site',
            path: 'home/sites/:id',
            components: {
                default: Home,
            },
        },
    ],
};

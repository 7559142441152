import api from './api.config';

/**
 * @typedef {Object} ImportAlert
 * @param {string} delay
 * @param {string} name
 * @param {string} pattern
 * @param {string[]} recipents
 * @param {boolean} triggered
 */

/**
 * Returns the documents resource URL.
 *
 * @param {string} customerCode Customer code
 * @return {string} URL
 */
function importAlertsUrl(customerCode) {
    return `/timeseries/v2/${customerCode}/import-alerts`;
}

/**
 * Retrieve import alerts from the REST API.
 *
 * @param {Object} params
 * @param {string} params.customerCode
 * @param {Object} params.fields
 * @param {Object} params.filters
 * @returns {ImportAlert[]}
 */
export async function getImportAlerts({ customerCode, fields, filters }) {
    try {
        const response = await api.get(importAlertsUrl(customerCode), {
            headers: {
                Accept: 'application/json',
            },
            params: {
                ...(fields && { _fields: fields.join() }),
                ...filters,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Retrieve an import alert from the REST API.
 *
 * @param {Object} params
 * @param {string} params.customerCode
 * @param {string} params.id
 * @returns {ImportAlert}
 */
export async function getImportAlert({ customerCode, id }) {
    try {
        const response = await api.get(`${importAlertsUrl(customerCode)}/${id}`, {
            headers: {
                Accept: 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}
/**
 * Create an import alert
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {Object} params.importAlert  Data of the import alert to create
 * @returns {ImportAlert}
 */
export async function createImportAlert({ customerCode, importAlert }) {
    try {
        const response = await api.post(importAlertsUrl(customerCode), importAlert, {
            headers: {
                Accept: 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Update an import alert
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {Action} params.importAlert The import alert to update
 */
export async function updateImportAlert({ customerCode, importAlert }) {
    try {
        const response = await api.put(`${importAlertsUrl(customerCode)}/${importAlert.id}`, importAlert, {
            headers: {
                Accept: 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Delete an import alert
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {string} params.id The import alert id
 */
export async function deleteImportAlert({ customerCode, id }) {
    try {
        await api.delete(`${importAlertsUrl(customerCode)}/${id}`, {
            headers: {
                Accept: 'application/json',
            },
        });
    } catch (error) {
        throw new Error(error.response);
    }
}

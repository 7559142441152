import _ from 'lodash';

export const actionTypes = {
    SAVE_QUERY_PARAMS: 'SAVE_QUERY_PARAMS',
};

export const mutationTypes = {
    SET_QUERY_PARAMS: 'SET_QUERY_PARAMS',
};

export default function ({ fields = [], namespaced = false }) {
    return {
        namespaced,
        state() {
            return {
                lastQueryParams: null,
            };
        },
        mutations: {
            /**
             * @param {State} state
             * @param lastQueryParams {Object} query params to save
             */
            [mutationTypes.SET_QUERY_PARAMS](state, lastQueryParams) {
                state.lastQueryParams = {
                    ...state.lastQueryParams,
                    ..._.pick(lastQueryParams, fields),
                };
            },
        },
        actions: {
            [actionTypes.SAVE_QUERY_PARAMS]({ commit }, params) {
                commit(mutationTypes.SET_QUERY_PARAMS, params);
            },
        },
    };
}

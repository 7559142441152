import { t } from 'i18next';
import { keyBy } from 'lodash';
import { createCategory, deleteCategory, getCategories, updateCategory } from '@/api/categories.service';
import { createTag } from '@/api/tags.service';
import { notify } from '@/helpers/notifications';
import { NAMESPACE as NS_TAGS } from '@/store/modules/tags';
import { GO_TO_TAGS } from '@/store/modules/tags/action-types';
import { DELETE_TAGS_BY_CATEGORY } from '@/store/modules/tags/mutation_types';
import dataModule from '@/store/reusable-modules/data-list.module';
import { addEdition } from '@/store/utils/edition.utils';
import { GO_TO_CATEGORY, GO_TO_CREATE_CATEGORY, UPDATE_CATEGORY } from './action-types';
import { UPDATE_CATEGORY_SUCCESS } from './mutation_types';

export const NAMESPACE = 'categories';

const _state = {};

const getters = {
    /**
     * Return the categories
     *
     * @param {CategoryState} state
     * @returns {array}} The categories list
     */
    categories: (state) => {
        return state?.categories?.items ?? [];
    },
    /**
     * Return the categories
     *
     * @param {CategoryState} state
     * @returns {array}} The categories list
     */
    categoriesById: (state, _getters) => {
        return keyBy(_getters.categories, 'id');
    },
    isFetchingCategories: (state) => {
        return state?.categories?.pending?.items ?? false;
    },
    /**
     * Return the category corresponding to the route `:id`. Otherwise `undefined`.
     *
     * @param {CategoryState} state
     * @param {CategoryGetters} _getters
     * @return {Category}
     */
    selectedCategory: (state, _getters) => {
        const categoryId = _getters.selectedCategoryId;
        return _getters.categories && categoryId !== null ? _getters.categoriesById[categoryId] : undefined;
    },
    /**
     * Return the ID corresponding to the route `:id`.
     *
     * @param {CategoryState} state
     * @param {CategoryGetters} _getters
     * @param {RootState} rootState
     * @return {string} ID of the category
     */
    selectedCategoryId: (state, _getters, rootState) => {
        return rootState.route?.params?.id;
    },
};

const mutations = {
    /**
     *
     * @param {*} state
     * @param {*} category
     */
    [UPDATE_CATEGORY_SUCCESS](state, category) {
        const index = state.categories.items.findIndex(({ id }) => id === category.id);
        if (index >= 0) {
            state.categories.items.splice(index, 1, category);
        }
    },
};

const actions = {
    /**
     * Navigate to a category
     * @param {*} context
     */
    [GO_TO_CATEGORY](context, { id, router }) {
        router.push({
            name: 'category-infos',
            params: { id },
            query: router.currentRoute.query,
        });
    },
    /**
     * Navigate to category creation form
     * @param {Object} context
     */
    [GO_TO_CREATE_CATEGORY](context, { router }) {
        router.push({ name: 'categories-new' });
    },

    /**
     * Update category
     *  @param {Object} context
     */
    async [UPDATE_CATEGORY]({ commit, rootGetters }, category) {
        try {
            await updateCategory({
                customerCode: rootGetters.customerCode,
                category,
            });
            notify({
                type: 'success',
                text: t('UPDATE_CATEGORY_SUCCESS'),
            });
            commit(UPDATE_CATEGORY_SUCCESS, category);
        } catch (error) {
            notify({
                type: 'error',
                text: t('UPDATE_CATEGORY_ERROR'),
            });
            throw error;
        }
    },
};

export default addEdition(
    {
        namespaced: true,
        state: _state,
        getters,
        actions,
        mutations,
        modules: {
            categories: dataModule({
                namespaced: false,
                async getItems({ rootGetters }) {
                    return getCategories({
                        customerCode: rootGetters.customerCode,
                    });
                },
                async deleteItem({ commit, dispatch, rootGetters }, { item, router }) {
                    try {
                        await deleteCategory({
                            customerCode: rootGetters.customerCode,
                            id: item.id,
                        });
                        commit(`${NS_TAGS}/${DELETE_TAGS_BY_CATEGORY}`, { id: item.id }, { root: true });
                        notify({ type: 'success', text: t('DELETE_CATEGORY_SUCCESS') });
                        dispatch(`${NS_TAGS}/${GO_TO_TAGS}`, { router }, { root: true });
                    } catch (error) {
                        notify({ type: 'error', text: t('DELETE_CATEGORY_ERROR') });
                        throw error;
                    }
                },
                async createItem({ dispatch, rootGetters }, { item, router }) {
                    const { customerCode } = rootGetters;
                    const { tags, ...category } = item;
                    try {
                        const newCategory = await createCategory({ customerCode, category });

                        const categoryId = newCategory.id;

                        const createTagPromises = (tags ?? []).map(async (name) =>
                            createTag({ customerCode, tag: { name, categoryId } }),
                        );
                        await Promise.all(createTagPromises);

                        dispatch(`${NAMESPACE}/${GO_TO_CATEGORY}`, { router, id: categoryId }, { root: true });
                        notify({ type: 'success', text: t('CREATE_CATEGORY_SUCCESS') });
                        return newCategory;
                    } catch (error) {
                        notify({ type: 'error', text: t('CREATE_CATEGORY_ERROR') });
                        throw error;
                    }
                },
            }),
        },
    },
    {
        saveFunction: actions[UPDATE_CATEGORY],
    },
);

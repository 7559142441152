import { t } from 'i18next';
import { keyBy } from 'lodash';
import { createGroup, deleteGroup, getGroups, updateGroup } from '@/api/groups.service';
import { notify } from '@/helpers/notifications';
import dataModule from '@/store/reusable-modules/data-list.module';
import { addEdition } from '@/store/utils/edition.utils';
import { GO_TO_CREATE_GROUP, GO_TO_GROUP, GO_TO_GROUPS, UPDATE_GROUP } from './action-types';
import { UPDATE_GROUP_SUCCESS } from './mutation-types';

export const NAMESPACE = 'groups';

const getters = {
    /**
     * Return the groups
     *
     * @param {GroupState} state
     * @returns {array}} The groups list
     */
    groups: (state) => {
        return state.groups.items ?? [];
    },

    isFetchingGroups: (state) => {
        return state.groups.pending?.items;
    },
    groupsById: (_, _getters) => {
        return keyBy(_getters.groups, 'id');
    },

    /**
     * Return the ID corresponding to the route `:id`.
     *
     * @param {GroupState} state
     * @param {GroupGetters} _getters
     * @param {RootState} rootState
     * @return {string} ID of the group
     */
    selectedGroupId: (state, _getters, rootState) => {
        return rootState.route?.params?.id;
    },

    /**
     * Return the group corresponding to the route `:id`. Otherwise `undefined`.
     *
     * @param {GroupState} state
     * @param {GroupGetters} _getters
     * @return {Group}
     */
    selectedGroup: (state, _getters) => {
        const groupId = _getters.selectedGroupId;
        return _getters.groups && groupId !== null ? _getters.groups.find(({ id }) => groupId === `${id}`) : undefined;
    },
};

const mutations = {
    /**
     * @param {GroupState} state
     * @param {Group} group
     */
    [UPDATE_GROUP_SUCCESS](state, group) {
        const array = [...(state.groups.items ?? [])];
        const index = array.findIndex(({ id }) => id === group.id);
        if (index >= 0) {
            array.splice(index, 1, group);
        }
        state.groups.items = array;
    },
};

const actions = {
    /**
     * Navigate to group creation form
     *
     * @param {Object} context
     */
    [GO_TO_CREATE_GROUP](context, { router }) {
        router.push({
            name: 'users-groups-new',
        });
    },

    /**
     * Navigate to group panel
     *
     * @param {Object} context
     */
    [GO_TO_GROUP](context, { router, id }) {
        router.push({
            name: 'users-group-infos',
            params: {
                id,
            },
        });
    },

    /**
     * Navigate to group list
     *
     * @param {Object} context
     */
    [GO_TO_GROUPS](context, { router }) {
        router.push({
            name: 'users-groups',
        });
    },

    /**
     * Update a group
     * @param {Group} group
     */
    async [UPDATE_GROUP]({ commit, rootGetters }, group) {
        try {
            await updateGroup({
                customerCode: rootGetters.customerCode,
                group,
            });
            notify({
                type: 'success',
                text: t('GROUP_UPDATE_SUCCESS'),
            });
            commit(UPDATE_GROUP_SUCCESS, group);
        } catch (error) {
            notify({
                type: 'error',
                text: t('GROUP_UPDATE_ERROR'),
            });
            throw error;
        }
    },
};

export default addEdition(
    {
        namespaced: true,
        getters,
        mutations,
        actions,
        modules: {
            groups: dataModule({
                namespaced: false,
                async getItems({ rootGetters }, { fields }) {
                    const { customerCode } = rootGetters;
                    const groups = await getGroups({ customerCode, fields });

                    // Use Object.freeze on items to avoid Vue observers
                    return Object.freeze(groups);
                },
                async createItem({ dispatch, rootGetters }, { item, router }) {
                    try {
                        const createdGroup = await createGroup({
                            customerCode: rootGetters.customerCode,
                            group: item,
                        });
                        notify({ type: 'success', text: t('CREATE_GROUP_SUCCESS') });

                        dispatch(GO_TO_GROUP, { router, id: createdGroup.id });
                        return createdGroup;
                    } catch (error) {
                        notify({ type: 'error', text: t('CREATE_GROUP_ERROR') });
                        throw error;
                    }
                },
                async deleteItem({ dispatch, rootGetters }, { item, router }) {
                    try {
                        await deleteGroup({
                            customerCode: rootGetters.customerCode,
                            id: item.id,
                        });
                        notify({
                            type: 'success',
                            text: t('DELETE_GROUP_SUCCESS'),
                        });
                        dispatch(`${NAMESPACE}/${GO_TO_GROUPS}`, { router }, { root: true });
                    } catch (e) {
                        notify({
                            type: 'error',
                            text: t('DELETE_GROUP_ERROR'),
                        });
                    }
                },
            }),
        },
    },
    {
        saveFunction: actions[UPDATE_GROUP],
    },
);

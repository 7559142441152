const Documents = () => import(/* webpackChunkName: "documents" */ '@/pages/Documents.vue');
const CreateDocumentsFolder = () =>
    import(/* webpackChunkName: "documents" */ '@/components/documents/CreateDocumentsFolder.vue');
const DocumentsFolderPanel = () =>
    import(/* webpackChunkName: "documents" */ '@/components/documents/DocumentsFolderPanel.vue');

export default {
    name: 'customer.documents',
    path: 'documents',
    component: Documents,
    children: [
        {
            name: 'documents-new-folder',
            path: 'new-folder',
            components: {
                fullPageLayer: CreateDocumentsFolder,
            },
        },
        {
            name: 'document-folder-panel',
            path: 'folder/:id',
            components: {
                layer: DocumentsFolderPanel,
            },
        },
    ],
};

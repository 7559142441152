/**
 * Reports service for use with Vue components (and VueX)
 */
import axios from 'axios';
import api, { getAbortController } from './api.config';

const abortControllers = {
    widgets: new Map(),
};

/**
 * Return the reports base URL.
 *
 * @param {string} customerCode Customer code
 * @return {string} URL
 */
function reportsBaseUrl(customerCode) {
    return `/timeseries/v2/${customerCode}/reports`;
}

/**
 * Retrieve all reports from the REST API.
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @return {Promise<Object[]>} List of Report data
 */
export async function getReports({ customerCode, fields, filters, sort }) {
    try {
        const response = await api.get(reportsBaseUrl(customerCode), {
            headers: {
                Accept: 'application/json',
            },
            params: {
                ...(fields && { _fields: fields.join() }),
                ...filters,
                _sort: sort,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Retrieve a given report from the REST API.
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {string} params.id ID of the report to retrieve
 * @return {Object} Report data
 */
export async function getReport({ customerCode, id }) {
    try {
        const response = await api.get(`${reportsBaseUrl(customerCode)}/${id}`, {
            headers: {
                Accept: 'application/json',
            },
        });

        return response.data;
    } catch (error) {
        throw error.response;
    }
}

/**
 * Update a widget.
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {string} params.id        ID of the report to update
 * @param {Object} params.widget    Data of the widget to update
 * @return {Object} The updated widget
 */
export async function updateWidget({ customerCode, id, widget, cancellable = false }) {
    try {
        abortControllers.widgets.set(widget.id, getAbortController(abortControllers.widgets.get(widget.id)));
        const response = await api.put(`${reportsBaseUrl(customerCode)}/${id}/widgets/${widget.id}`, widget, {
            headers: { Accept: 'application/json' },
            ...(cancellable && { signal: abortControllers.widgets.get(widget.id).signal }),
        });
        abortControllers.widgets.delete(widget.id);
        return response.data;
    } catch (error) {
        if (!axios.isCancel(error)) {
            throw new Error(error.response.data);
        }
    }
}

export async function generatePDF({ customerCode, id, startDate, endDate, timestep }) {
    try {
        const response = await api.post(
            `${reportsBaseUrl(customerCode)}/${id}/pdf?startDate=${startDate}&endDate=${endDate}&timestep=${timestep}`,
            {
                headers: {
                    Accept: 'application/json',
                },
            },
        );
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

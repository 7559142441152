import { subject } from '@casl/ability';
import { getCurrentInstance } from 'vue';

/**
 * Composable that returns the CASL Ability injected instance.
 * Temporary implementation before using @casl/vue for Vue 3.
 */
export const useAbility = () => {
    const proxy = getCurrentInstance().proxy;
    return {
        can: proxy.$ability.can.bind(proxy.$ability),
        subject,
    };
};

import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import { t } from 'i18next';
import { computed, unref } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import {
    createImportAlert,
    deleteImportAlert,
    getImportAlert,
    getImportAlerts,
    updateImportAlert,
} from '@/api/import-alerts.service';
import { notify } from '@/helpers/notifications';
import { confirmDeletion } from '@/store/utils/edition.utils';
import { useCustomerCode } from '@/composables/useCustomerCode';
import { usePostHog } from '@/composables/usePostHog';

export const useGetImportAlerts = ({ options } = {}) => {
    const customerCode = useCustomerCode();

    return useQuery({
        queryKey: ['importAlerts', customerCode.value],
        queryFn: () =>
            getImportAlerts({
                customerCode: customerCode.value,
            }),
        refetchInterval: 60000,
        ...options,
    });
};

export const useImportAlertIndicator = () => {
    const { data } = useGetImportAlerts({
        options: {
            select: (alerts) => alerts.filter((alert) => alert.triggered),
        },
    });

    return computed(() => {
        if (!data.value) {
            return '';
        }
        return data.value.length > 0 ? 'error' : 'success';
    });
};

export const useSelectedImportAlert = () => {
    const customerCode = useCustomerCode();
    const route = useRoute();

    return useQuery({
        queryKey: ['importAlert', computed(() => route.params.id)],
        queryFn: () =>
            getImportAlert({
                customerCode: customerCode.value,
                id: unref(route.params.id),
            }),
        enabled: computed(() => !!route.params.id),
    });
};

/**
 * Returns a composable to create a new import alert.
 *
 * @returns Composable
 *
 * @usage
 * ```
 * const { mutate: createImportAlert } = useCreateImportAlert();
 * ```
 */
export const useCreateImportAlert = () => {
    const customerCode = useCustomerCode();
    const queryClient = useQueryClient();
    const { capture } = usePostHog();
    const router = useRouter();

    return useMutation({
        mutationFn: (newImportAlert) =>
            createImportAlert({
                customerCode: customerCode.value,
                importAlert: newImportAlert,
            }),
        onSuccess: async (importAlert) => {
            notify({
                type: 'success',
                text: t('CREATE_IMPORT_ALERT_SUCCESS'),
            });

            capture('create import alert', { name: importAlert.name, customerCode: customerCode.value });
            queryClient.invalidateQueries({ queryKey: ['importAlerts', customerCode.value] });

            router.push({
                name: 'import-alerts',
            });
        },
        onError: () => {
            notify({
                type: 'error',
                text: t('CREATE_IMPORT_ALERT_ERROR'),
            });
        },
    });
};

/**
 * Returns a composable to update an import alert.
 *
 * @returns Composable
 *
 * @usage
 * ```
 * const { mutate: updateImportAlert } = useUpdateImportAlert();
 * ```
 */
export const useUpdateImportAlert = () => {
    const customerCode = useCustomerCode();
    const queryClient = useQueryClient();
    const { data: selectedImportAlert } = useSelectedImportAlert();

    return useMutation({
        mutationFn: (importAlert) =>
            updateImportAlert({
                customerCode: customerCode.value,
                importAlert,
            }),
        onSuccess: async () => {
            notify({
                type: 'success',
                text: t('UPDATE_IMPORT_ALERT_SUCCESS'),
            });
            queryClient.invalidateQueries({ queryKey: ['importAlerts', customerCode.value] });
            queryClient.invalidateQueries({ queryKey: ['importAlert', selectedImportAlert.value?.id] });
        },
        onError: () => {
            notify({
                type: 'error',
                text: t('UPDATE_IMPORT_ALERT_ERROR'),
            });
        },
    });
};

/**
 * Returns a composable to delete an import alert.
 *
 * @returns Composable
 *
 * @usage
 * ```
 * const { mutate: deleteImportAlert } = useDeleteImportAlert();
 * ```
 */
export const useDeleteImportAlert = () => {
    const customerCode = useCustomerCode();
    const queryClient = useQueryClient();
    const router = useRouter();

    return useMutation({
        mutationFn: async (importAlert) => {
            const resultConfirmDeletion = await confirmDeletion();
            if (!resultConfirmDeletion) {
                throw new Error('Deletion not confirmed');
            }
            return deleteImportAlert({ customerCode: customerCode.value, id: importAlert.id });
        },
        onSuccess: async () => {
            notify({
                type: 'success',
                text: t('DELETE_IMPORT_ALERT_SUCCESS'),
            });

            router.push({
                name: 'import-alerts',
            });

            queryClient.invalidateQueries({ queryKey: ['importAlerts', customerCode.value] });
        },
        onError: () => {
            notify({
                type: 'error',
                text: t('DELETE_IMPORT_ALERT_ERROR'),
            });
        },
    });
};

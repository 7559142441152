import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import { useLocalStorage } from '@vueuse/core';
import { t } from 'i18next';
import { arrayToTree } from 'performant-array-to-tree';
import { computed, unref } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import {
    createFolder as createFolderInAPI,
    deleteFolder as deleteFolderInAPI,
    getFolders,
    updateFolder as updateFolderInAPI,
} from '@/api/folders.service';
import { notify } from '@/helpers/notifications';
import { confirmDeletion } from '@/store/utils/edition.utils';
import { useCustomerCode } from './useCustomerCode';

/**
 * Returns a composable to get folders.
 *
 * @param {string} context    Context
 * @returns {Object} The composable
 */
function useGetFolders(context) {
    const customerCode = useCustomerCode();
    return useQuery({
        queryKey: ['folders', { customerCode, context }],
        queryFn: () =>
            getFolders({
                customerCode: customerCode.value,
                filters: {
                    context,
                },
            }),
    });
}

/**
 * Returns a composable to create a folder.
 *
 * @param {string} context    Context of the folder
 * @param {string} redirectTo Name of the route to redirect to after the folder is created
 * @returns {Object} The composable
 */
function useCreateFolder(context, redirectTo) {
    const customerCode = useCustomerCode();
    const queryClient = useQueryClient();
    const router = useRouter();
    const lastExpandedItemId = useLocalStorage(`${context}/lastExpandedItemId`, null);

    return useMutation({
        mutationFn: (newFolder) =>
            createFolderInAPI({
                customerCode: customerCode.value,
                folder: {
                    description: newFolder.description ?? '',
                    context,
                    ...newFolder,
                },
            }),
        onSuccess: (newFolder) => {
            queryClient.setQueryData(['folders', { customerCode: customerCode.value, context }], (oldData) =>
                oldData ? [...oldData, newFolder] : oldData,
            );
            notify({
                type: 'success',
                text: t('CREATE_FOLDER_SUCCESS'),
            });
            const route = redirectTo(newFolder.id);
            router.push({
                ...route,
                query: {
                    ...route.query,
                    view: 'tree',
                },
            });
            lastExpandedItemId.value = newFolder.id;
        },
        onError: () => {
            notify({
                type: 'error',
                text: t('CREATE_FOLDER_ERROR'),
            });
        },
    });
}

/**
 * Returns a composable to update a folder.
 *
 * @param {string} context    Context of the folder
 * @returns {Object} The composable
 */
function useUpdateFolder(context) {
    const customerCode = useCustomerCode();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (folder) => updateFolderInAPI({ customerCode: customerCode.value, folder }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['folders', { customerCode, context }] });
        },
        onError: () => {
            notify({
                type: 'error',
                text: t('UPDATE_FOLDER_ERROR'),
            });
        },
    });
}

/**
 * Returns a composable to delete a folder.
 *
 * @param {string}   context              Context of the folder
 * @param {string}   redirectTo           Name of the route to redirect to after the folder is deleted
 * @param {Function} onDeleteFolder       Function called after a folder has been deleted
 * @param {string}   itemsAndFoldersById  Items and folders by id
 * @returns {Object} The composable
 */
function useDeleteFolder(context, redirectTo, onDeleteFolder, itemsAndFoldersById) {
    const customerCode = useCustomerCode();
    const queryClient = useQueryClient();
    const router = useRouter();
    const lastExpandedItemId = useLocalStorage(`${context}/lastExpandedItemId`, null);

    return useMutation({
        mutationFn: async (folder) => {
            if (!(await confirmDeletion())) {
                return false;
            }

            return deleteFolderInAPI({ customerCode: customerCode.value, id: folder.id });
        },
        onSuccess: (data, deletedFolder) => {
            if (data === false) {
                return;
            }
            if (deletedFolder.id === lastExpandedItemId.value) {
                const parentId = itemsAndFoldersById.value[deletedFolder.id]?.parentId;
                router.push(redirectTo(parentId));
                lastExpandedItemId.value = parentId;
            }

            if (onDeleteFolder && typeof onDeleteFolder === 'function') {
                onDeleteFolder(deletedFolder);
            }

            queryClient.setQueryData(['folders', { customerCode, context }], (oldData) =>
                oldData ? oldData.filter((folder) => folder.id !== deletedFolder.id) : oldData,
            );

            notify({
                type: 'success',
                text: t('DELETE_FOLDER_SUCCESS'),
            });
        },
        onError: () => {
            notify({
                type: 'error',
                text: t('DELETE_FOLDER_ERROR'),
            });
        },
    });
}

/**
 * Returns a composable to move an item to a parent.
 *
 * @param {string}              context               Context of the folder
 * @param {ComputedRef<Object>} itemsAndFoldersById   Items and folders by id
 * @param {Function}            moveItem              Function to move an item
 * @returns {Object} The composable
 */
export function useMoveItem(context, itemsAndFoldersById, moveItem) {
    const customerCode = useCustomerCode();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ moved, to }) => {
            const movedItem = itemsAndFoldersById.value[moved];
            const toItem = to === 'root' ? { id: null } : itemsAndFoldersById.value[to];
            if (movedItem && toItem) {
                const updatedItem = {
                    ...movedItem,
                    parentId: toItem.id,
                };

                if (movedItem.type === 'folder') {
                    return updateFolderInAPI({
                        customerCode: customerCode.value,
                        folder: updatedItem,
                    });
                }

                return moveItem(updatedItem);
            }
        },
        onSuccess: (data) => {
            if (!data) {
                return;
            }
            notify({
                type: 'success',
                text: t('ITEM_MOVE_SUCCESS'),
            });

            queryClient.invalidateQueries({ queryKey: ['folders', { customerCode, context }] });
        },
        onError: () => {
            notify({
                type: 'error',
                text: t('ITEM_ITEM_MOVE_ERROR'),
            });

            queryClient.invalidateQueries({ queryKey: ['folders', { customerCode, context }] });
        },
    });
}

/**
 * Returns a composable allowing to manage folders.
 *
 * @param {*}        params
 * @param {string}   params.context                Context of the folder
 * @param {Array}    params.items                  Items to display
 * @param {Function} params.redirectToAfterCreate  Function returning the route to redirect after folder is created
 * @param {Function} params.redirectToAfterDelete  Function returning the route to redirect after folder is deleted
 * @param {Function} params.onDeleteFolder         Function called after a folder has been deleted
 * @param {Function} params.moveItem               Function to move an item
 * @param {Array}    params.containerTypes         Types of containers
 *
 * @returns Composable
 */
export const useFolders = ({
    context,
    items,
    redirectToAfterCreate,
    redirectToAfterDelete = redirectToAfterCreate,
    onDeleteFolder,
    moveItem = async () => {},
    containerTypes = ['folder'],
}) => {
    const route = useRoute();
    const lastExpandedItemId = useLocalStorage(`${context}/lastExpandedItemId`, null);

    const { data: folders, isPending: isPendingFolders } = useGetFolders(context);

    const itemsAndFolders = computed(() => {
        return [
            ...(unref(items) || []),
            ...(folders.value || []).map((folder) => ({
                type: 'folder',
                icon: { name: 'folder' },
                ...folder,
            })),
        ];
    });

    const itemsAndFoldersById = computed(() => {
        return itemsAndFolders.value.reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
        }, {});
    });

    const selectedItemId = computed(() => {
        return route.params.id || route.query.folder || route.query.id;
    });

    const selectedItem = computed(() => {
        return itemsAndFoldersById.value[selectedItemId.value];
    });

    const tree = computed(() => {
        return {
            id: 'root',
            children: arrayToTree(itemsAndFolders.value, { dataField: null }).map((rootItem) => ({
                ...rootItem,
                parentId: 'root',
            })),
        };
    });

    const getParentFolder = (itemId) => {
        const item = itemsAndFoldersById.value[itemId];

        if (!item) {
            return null;
        }
        if (containerTypes.includes(item.type)) {
            return item;
        }

        return getParentFolder(item.parentId);
    };

    return {
        folders,
        isPendingFolders,
        itemsAndFolders,
        parentFolder: computed(() => getParentFolder(lastExpandedItemId.value)),
        getItemById: (id) => itemsAndFoldersById.value[id],
        tree,
        selectedItem,
        selectedItemId,
        selectedType: computed(() => selectedItem.value?.type),
        createFolder: useCreateFolder(context, redirectToAfterCreate),
        updateFolder: useUpdateFolder(context),
        deleteFolder: useDeleteFolder(context, redirectToAfterDelete, onDeleteFolder, itemsAndFoldersById),
        moveItem: useMoveItem(context, itemsAndFoldersById, moveItem),
        lastExpandedItemId,
        setLastExpandedItemId: (id) => {
            lastExpandedItemId.value = id;
        },
    };
};

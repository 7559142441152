export const GO_TO_ITEM = 'GO_TO_ITEM';
export const GO_TO_ITEMS = 'GO_TO_ITEMS';
export const GO_TO_ITEM_CONFIGURATION = 'GO_TO_ITEM_CONFIGURATION';
export const FETCH_METER_TIMESERIES_CSV = 'FETCH_METER_TIMESERIES_CSV';
export const APPLY_ZOOM = 'APPLY_ZOOM';
export const FETCH_PERIMETER = 'FETCH_PERIMETER';
export const GO_TO_CREATE_ITEM = 'GO_TO_CREATE_ITEM';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const MOVE_ITEM = 'MOVE_ITEM';
export const ADD_POINT = 'ADD_POINT';

<template>
    <div class="About flex flex-column pl2">
        <a v-tooltip="about.tooltip" class="version f7 ttu link" :href="about.href" target="_blank">
            {{ $t('VERSION_MENU') }} {{ about.version }}
        </a>
        <span class="about f8 ttl" v-html="about.label" />
    </div>
</template>
<script>
import { UiTooltip as tooltip } from '@energiency/ui';

export default {
    name: 'about',
    directives: {
        tooltip,
    },
    props: {
        about: {
            type: Object,
            default: () => {},
        },
    },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
@import (reference) '~style/colors.less';

.About {
    white-space: nowrap;
    .version {
        font-weight: 700;
        color: var(--grey-color-dark);
        cursor: pointer;
    }
}
</style>

import moment from 'moment';
import { getEvents } from '@/api/events.service';
import { dateRangeGetters } from '@/store/getters.utils';
import dataModule from '@/store/reusable-modules/data-list.module';
import queryParamsModule from '@/store/reusable-modules/query-params.module';
import { GO_TO_ACTIVITY_LOG } from './action-types';

export const NAMESPACE = 'events';

const getters = {
    ...dateRangeGetters,
};

const actions = {
    /**
     * Navigate to activity log.
     *
     * @param {Object} context
     * @param {Object} payload
     */
    [GO_TO_ACTIVITY_LOG](context, { router, startDate, endDate }) {
        router.push({
            name: 'customer.activity',
            query: {
                startDate: moment(startDate).toISOString(),
                endDate: moment(endDate).toISOString(),
            },
        });
    },
};

export default {
    namespaced: true,
    getters,
    actions,
    modules: {
        events: dataModule({
            namespaced: false,
            async getItems({ rootGetters }, { filters, fields, sort }) {
                return getEvents({ customerCode: rootGetters.customerCode, filters, fields, sort });
            },
        }),
        queryParams: queryParamsModule({
            namespaced: false,
            fields: ['startDate', 'endDate'],
        }),
    },
};

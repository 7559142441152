import { useLocalStorage } from '@vueuse/core';
import { computed, watch } from 'vue';
import { useRoute } from 'vue-router/composables';

/**
 * Composable to get the view from the route or from the local storage.
 *
 * @param {string} context     Context of the view (e.g. 'actions')
 * @param {string} defaultView Default view (e.g. 'list')
 * @returns Composable
 *
 * @example
 * ``js
 * import { useView } from '@/composables/useView';
 *
 * const { view } = useView('actions', 'list');
 * ```
 */
export const useView = (context, defaultView) => {
    const route = useRoute();
    const lastView = useLocalStorage(`${context}/view`, defaultView);

    const view = computed(() => {
        return route.query?.view || lastView.value;
    });

    watch(
        () => route.query?.view,
        async (newView) => {
            if (newView) {
                lastView.value = newView;
            }
        },
    );

    return { view };
};

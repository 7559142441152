import { t } from 'i18next';
import { keyBy } from 'lodash';
import { createApiKey, deleteAPIKey, getAPIKeys } from '@/api/api-keys.service';
import { notify } from '@/helpers/notifications';
import dataModule from '@/store/reusable-modules/data-list.module';
import { GO_TO_API_KEYS, GO_TO_CREATE_API_KEY } from './action-types';

export const NAMESPACE = 'apiKeys';

const getters = {
    /**
     * Returns the API keys by id
     *
     * @param {ApiKeysState} state
     * @returns {Object}
     */
    apiKeysById: (state) => {
        return keyBy(state.apiKeys.items, 'id');
    },
    /**
     * Returns the API key name by id
     *
     * @param {ApiKeysState} state
     * @returns {String}
     */
    apiKeyNameById: (state, _getters) => (id) => {
        return _getters.apiKeysById[id]?.name;
    },

    /**
     * Indicates whether the API keys are being fetched
     * @param {ApiKeysState}  state
     * @returns {boolean}   `true` if API keys are being fetched
     */
    isFetchingApiKeys: (state) => state.apiKeys.pending.items,
};

const actions = {
    /**
     * Navigate to API keys list.
     *
     * @param {Object} context
     */
    [GO_TO_API_KEYS](context, { router }) {
        router.push({ name: 'api-keys' });
    },

    /**
     * Navigate to API key creation form
     * @param {Object} context
     */
    [GO_TO_CREATE_API_KEY](context, { router }) {
        router.push({ name: 'api-keys-new' });
    },
};

export default {
    namespaced: true,
    getters,
    actions,
    modules: {
        apiKeys: dataModule({
            namespaced: false,
            async getItems({ rootGetters }, { fields }) {
                return getAPIKeys({ customerCode: rootGetters.customerCode, fields });
            },
            async createItem({ rootGetters }, { item }) {
                try {
                    const generatedApiKey = await createApiKey({ customerCode: rootGetters.customerCode, item });
                    notify({ type: 'success', text: t('CREATE_API_KEY_SUCCESS') });
                    return generatedApiKey;
                } catch (error) {
                    notify({ type: 'error', text: t('CREATE_API_KEY_ERROR') });
                    throw error;
                }
            },
            async deleteItem({ rootGetters }, { item }) {
                try {
                    await deleteAPIKey({
                        customerCode: rootGetters.customerCode,
                        id: item.id,
                    });
                    notify({ type: 'success', text: t('DELETE_API_KEYS_SUCCESS') });
                } catch (error) {
                    notify({ type: 'error', text: t('DELETE_API_KEYS_ERROR') });
                    throw error;
                }
            },
        }),
    },
};

import { memoize } from 'lodash';
import short from 'short-uuid';
import { v4 as uuidv4 } from 'uuid';

const getTranslator = memoize(() => short());

/**
 * Generate a new share link ID.
 *
 * @returns {string} New ID
 */
export function generateShareLinkId() {
    return uuidv4();
}

/**
 * Return a share link URL.
 *
 * @param {string} customerCode Customer code
 * @param {string} dashboardId Dashboard ID
 * @param {string} shareLinkId Share link ID
 * @returns {string} URL
 */
export const getShareLinkUrl = memoize(
    (customerCode, dashboardId, shareLinkId) => {
        const shortDashboardId = getTranslator().fromUUID(dashboardId);
        const shortLinkId = getTranslator().fromUUID(shareLinkId);

        const param = `customer=${customerCode}&dashboard=${shortDashboardId}&link=${shortLinkId}`;
        const base64Param = btoa(param);

        return `${window.location.origin}/s/${base64Param}`;
    },
    (...args) => JSON.stringify(args),
);

/**
 * Return share infos parsed from share link params.
 *
 * @param {string} shareLinkParam Share link parameter
 * @returns {string} Infos with `customerCode`, `dashboardId`, `linkId`
 */
export const parseShareLinkParam = memoize((shareLinkParam) => {
    const searchParams = new URLSearchParams(atob(shareLinkParam));
    const customerCode = searchParams.get('customer');
    const shortDashboardId = searchParams.get('dashboard');
    const shortLinkId = searchParams.get('link');

    if (!customerCode || !shortDashboardId || !shortLinkId) {
        return null;
    }

    return {
        customerCode,
        dashboardId: getTranslator().toUUID(shortDashboardId),
        linkId: getTranslator().toUUID(shortLinkId),
    };
});

export const getShareLinkParams = (route) => {
    if (!route?.meta?.isPublic || !route?.params?.shareParam) {
        return null;
    }

    return parseShareLinkParam(route.params.shareParam);
};

export const ADDING_POINT = 'ADDING_POINT';
export const ADD_POINT_SUCCESS = 'ADD_POINT_SUCCESS';
export const ADD_POINT_ERROR = 'ADD_POINT_ERROR';
export const FETCHING_ITEM_TIMESERIES_CSV = 'FETCHING_ITEM_TIMESERIES_CSV';
export const FETCH_ITEM_TIMESERIES_CSV_SUCCESS = 'FETCH_ITEM_TIMESERIES_CSV_SUCCESS';
export const FETCH_ITEM_TIMESERIES_CSV_ERROR = 'FETCH_ITEM_TIMESERIES_CSV_ERROR';
export const SET_LAST_SELECTED_ITEM_ID = 'SET_LAST_SELECTED_ITEM_ID';
export const UPDATE_ITEM_SUCCESS = 'UPDATE_ITEM_SUCCESS';
export const ZOOM_APPLIED = 'ZOOM_APPLIED';
export const ZOOM_RESET = 'ZOOM_RESET';

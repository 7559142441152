import { t } from 'i18next';
import _ from 'lodash';
import { getDashboard, getDashboards } from '@/api/dashboards.service';
import { getReport, getReports, updateWidget } from '@/api/reports.service';
import { notify } from '@/helpers/notifications';
import dataModule from '@/store/reusable-modules/data-list.module';
import gridModule, { mutationTypes as gridMutationTypes } from '@/store/reusable-modules/grid.module';
import { GO_TO_REPORT, GO_TO_REPORTS, UPDATE_WIDGET } from './action-types';
import {
    SET_QUERY_PARAMS,
    UPDATE_REPORT_CANCEL,
    UPDATE_REPORT_ERROR,
    UPDATE_REPORT_PENDING,
    UPDATE_REPORT_SUCCESS,
} from './mutation-types';

export const NAMESPACE = 'reports';

/** @type {ReportState} */
const _state = {
    editedWidget: null,
    isUpdatingReport: false,
    lastQueryParams: null,
};

export const getters = {
    /**
     * Return the reports
     *
     * @param {ReportState} state
     * @returns {array}} The report list
     */
    reports: (state) => {
        return _.get(state, 'reports.items') || [];
    },
    /**
     * Return the report's templates
     *
     * @param {ReportState} state
     * @returns {array}} The report templatelist
     */
    templates: (state) => {
        return _.get(state, 'templates.items') || [];
    },
    selectedItemId: (state, _getters, rootState) => {
        return rootState.route.params?.id;
    },
    /**
     * Return the current report
     *
     * @param {report} state
     * @returns {report} report
     */
    selectedItem: (state) => {
        return state.reports.item ?? {};
    },
    /**
     * Start of the time range.
     *
     * @param {*} state
     * @param {*} _getters
     * @param {*} rootState
     * @returns {string}
     */
    start: (state, _getters, rootState) =>
        rootState.route.query?.startDate || _getters.selectedItem?.dateRange.startDate,
    /**
     * End of the time range.
     *
     * @param {*} state
     * @param {*} _getters
     * @param {*} rootState
     * @returns {string}
     */
    end: (state, _getters, rootState) => rootState.route.query?.endDate || _getters.selectedItem?.dateRange.endDate,
    /**
     * Start of the time range.
     *
     * @param {*} state
     * @param {*} _getters
     * @returns {date}
     */
    startDate: (state, _getters) => (_getters.start ? new Date(_getters.start) : undefined),

    /**
     * End of the time range.
     *
     * @param {*} state
     * @param {*} _getters
     * @returns {date}
     */
    endDate: (state, _getters) => (_getters.end ? new Date(_getters.end) : undefined),
    /**
     * Timestep
     *
     * @param {*} state
     * @param {*} _getters
     * @param {*} rootState
     * @returns {string}
     */
    timestep: (state, _getters, rootState) => {
        const timestep = _getters.selectedItem
            ? _getters.selectedItem.timestep
            : _.get(rootState.route, 'query.timestep');
        return timestep || 'PT1H';
    },
    /**
     * List of available timesteps
     *
     * @param {ReportState} state
     * @param {*} _getters
     * @returns {string[]}
     */
    timesteps: (state, _getters) => _getters.selectedItem?.timesteps,
};

export const mutations = {
    /**
     * @param {ReportState} state
     * @param {Object} lastQueryParams query params to save
     */
    [SET_QUERY_PARAMS](state, lastQueryParams) {
        state.lastQueryParams = _.pick(lastQueryParams, ['startDate', 'endDate']);
    },
    /**
     * @param {ReportState} state
     */
    [UPDATE_REPORT_PENDING](state) {
        state.isUpdatingReport = true;
    },
    /**
     * @param {ReportState} state
     * @param {Report} report
     */
    [UPDATE_REPORT_SUCCESS](state, report) {
        state.isUpdatingReport = false;
        if (state.reports && state.reports.items) {
            const index = state.reports.items.findIndex(({ id }) => id === report.id);
            if (index >= 0) {
                state.reports.items.splice(index, 1, { ...report });
            }
        }
        state.reports.item = report;
    },
    /**
     * @param {ReportState} state
     */
    [UPDATE_REPORT_CANCEL](state) {
        state.isUpdatingReport = false;
    },
    /**
     * @param {ReportState} state
     */
    [UPDATE_REPORT_ERROR](state) {
        notify({
            type: 'error',
            text: t('UPDATE_REPORT_ERROR'),
        });
        state.isUpdatingReport = false;
    },
};

export const actions = {
    /**
     * Navigate to a report.
     *
     * @param {Object} context
     * @param {Object} payload
     */
    [GO_TO_REPORT]({ commit }, { id, router }) {
        router.push({
            name: 'customer.report',
            params: {
                id,
            },
        });
        commit(gridMutationTypes.SET_WIDGET_FULLSCREEN, null);
    },
    /**
     * Navigate to reports list
     *
     * @param {Object} context
     * @param {Object} payload
     */
    [GO_TO_REPORTS](context, { router, startDate, endDate }) {
        router.push({
            name: 'customer.reports',
            query: {
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
            },
        });
    },
    async [UPDATE_WIDGET]({ commit, getters: _getters, rootGetters }, widget) {
        try {
            const report = _getters.selectedItem;
            commit(UPDATE_REPORT_PENDING);
            const updatedWidget = await updateWidget({
                customerCode: rootGetters.customerCode,
                id: report.id,
                widget,
                cancellable: true,
            });
            if (updatedWidget) {
                const widgets = report.widgets.map((w) => (w.id === widget.id ? widget : w));
                commit(UPDATE_REPORT_SUCCESS, { ...report, modificationDate: updatedWidget.modificationDate, widgets });
            } else {
                commit(UPDATE_REPORT_CANCEL);
            }
        } catch (error) {
            commit(UPDATE_REPORT_ERROR);
            throw new Error(error.response.data);
        }
    },
};

export default {
    namespaced: true,
    state: _state,
    getters,
    actions,
    mutations,
    modules: {
        reports: dataModule({
            namespaced: false,
            async getItem({ rootGetters }, id) {
                return getReport({ customerCode: rootGetters.customerCode, id });
            },
            async getItems({ rootGetters }, { fields, filters, sort }) {
                return getReports({ customerCode: rootGetters.customerCode, fields, filters, sort });
            },
        }),
        templates: dataModule({
            namespaced: true,
            async getItem({ rootGetters }, id) {
                return getDashboard({ customerCode: rootGetters.customerCode, id });
            },
            async getItems({ rootGetters }, { fields }) {
                const filters = { 'scheduling.enabled': 'true' };
                return getDashboards({ customerCode: rootGetters.customerCode, fields, filters });
            },
        }),
        grid: gridModule({
            namespaced: false,
            getGrid: (state, _getters, rootState) => rootState[NAMESPACE].reports.item,
            updateGridWidget: ({ dispatch }, { widget }) => dispatch(UPDATE_WIDGET, widget),
        }),
    },
};

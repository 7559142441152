const Dashboard = () => import(/* webpackChunkName: "dashboards" */ '@/pages/Dashboard.vue');
const DashboardWidgetPanel = () =>
    import(/* webpackChunkName: "dashboards" */ '@/components/dashboards/DashboardWidgetPanel.vue');
const DashboardWidgetCreationPanel = () =>
    import(/* webpackChunkName: "dashboards" */ '@/components/dashboards/panels/DashboardWidgetCreationPanel');
const DashboardPanel = () => import(/* webpackChunkName: "dashboards" */ '@/components/dashboards/DashboardPanel.vue');
const DashboardWidgetThreads = () =>
    import(/* webpackChunkName: "comments" */ '@/components/dashboards/DashboardWidgetThreads.vue');

export default {
    name: 'customer.dashboard',
    path: ':id',
    component: Dashboard,
    meta: {
        title: 'DASHBOARDS_TITLE',
    },
    children: [
        {
            name: 'dashboard-config',
            path: 'config',
            redirect: { name: 'dashboard-informations' },
            components: {
                layer: DashboardPanel,
            },
            children: [
                {
                    name: 'dashboard-informations',
                    path: 'informations',
                },
                {
                    name: 'dashboard-layout',
                    path: 'layout',
                },
                {
                    name: 'dashboard-variables',
                    path: 'variables',
                },
                {
                    name: 'dashboard-reports',
                    path: 'reports',
                },
            ],
        },
        {
            name: 'widget',
            path: 'widget/:widgetId',
            redirect: { name: 'widget-data' },
            components: {
                layer: DashboardWidgetPanel,
            },
            children: [
                {
                    name: 'widget-data',
                    path: 'data',
                },
                {
                    name: 'widget-conditional-formatting',
                    path: 'conditional-formatting',
                },
                {
                    name: 'widget-options',
                    path: 'parameters',
                },
            ],
        },
        {
            name: 'widget-comments',
            path: 'widget/:widgetId/comments',
            components: {
                layer: DashboardWidgetThreads,
            },
        },
        {
            name: 'create-widget',
            path: 'new',
            components: {
                layer: DashboardWidgetCreationPanel,
            },
        },
    ],
};

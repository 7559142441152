const _state = {
    content: localStorage.getItem('clipboard'),
};

const getters = {
    parsedContent(state) {
        return state.content ? JSON.parse(state.content) : undefined;
    },
};

const mutations = {
    copy(state, content) {
        state.content = typeof content === 'string' ? content : JSON.stringify(content);
        localStorage.setItem('clipboard', state.content);
    },
    clear(state) {
        state.content = null;
        localStorage.removeItem('clipboard');
    },
};

export const NAMESPACE = 'clipboard';

export default {
    namespaced: true,
    state: _state,
    getters,
    mutations,
};

import api from './api.config';

/**
 * @typedef {Object} DataType
 * @property {string} id            id of the data type
 * @property {string} name          name of the data type
 * @property {string} defaultUnit   symbol of the default unit
 * @property {Array<Unit>} units    list of the units
 */

/**
 * @typedef {Object} Unit
 * @property {string} symbol    symbol of the unit
 * @property {number} coef      coef according to the default unit
 */

/**
 * Returns the data types resource URL.
 *
 * @param {string} customerCode Customer code
 * @return {string} URL
 */
function dataTypesUrl(customerCode) {
    return `/timeseries/v2/${customerCode}/data-types`;
}

/**
 * Gets a single data type.
 *
 * @param {Object}   params
 * @param {string}   params.customerCode Customer code
 * @param {string}   params.id ID of the data type to retrieve
 * @param {string}   params.lang Language
 * @return {Promise<DataType>} A data type
 */
export async function getDataType({ customerCode, id, lang = '' }) {
    const url = dataTypesUrl(customerCode);
    try {
        const response = await api.get(`${url}/${id}`, {
            headers: {
                Accept: 'application/json',
                'Accept-Language': lang,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Gets data types list.
 *
 * @param {Object}   params
 * @param {string}   params.customerCode Customer code
 * @param {Array}    params.fields Fields to retrieve, if none all
 * @param {Object}   params.filters List of filters
 * @param {string}   params.lang Language
 * @return {Promise<DataType[]>} List of data types
 */
export async function getDataTypes({ customerCode, fields, filters, sort, lang = '' }) {
    const url = dataTypesUrl(customerCode);
    try {
        const response = await api.get(url, {
            headers: {
                Accept: 'application/json',
                'Accept-Language': lang,
            },
            params: {
                ...(fields && { _fields: fields.join() }),
                ...(sort && { _sort: sort }),
                ...filters,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Create a new data type
 *
 * @param {Object}    params
 * @param {String}    params.customerCode Customer code
 * @param {DataType}  params.dataType The data type to create
 * @returns {Promise<DataType>}
 */
export async function createDataType({ customerCode, dataType }) {
    try {
        const response = await api.post(`${dataTypesUrl(customerCode)}`, dataType, {
            headers: {
                Accept: 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Update a data type
 *
 * @param {Object}   params
 * @param {string}   params.customerCode Customer code
 * @param {DataType} params.dataType   Data of the data type to update
 * @return {Promise<DataType>} The updated data type
 */
export async function updateDataType({ customerCode, dataType }) {
    const url = dataTypesUrl(customerCode);
    try {
        const response = await api.put(`${url}/${dataType.id}`, dataType, {
            headers: { Accept: 'application/json' },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Delete a data type
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {DataType} params.id     ID of the data type to delete
 * @return {Promise<void>}
 */
export async function deleteDataType({ customerCode, id }) {
    const url = dataTypesUrl(customerCode);
    try {
        return api.delete(`${url}/${id}`, {
            headers: { Accept: 'application/json' },
        });
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Get a data type's references
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {DataType} params.id     ID of the data type to delete
 * @returns {Promise<{data: Array<{id:string, name: string}>, total: number}>} A part of references and the reference's count
 */
export async function getDataTypeReferences({ customerCode, id }) {
    try {
        const response = await api.get(`/timeseries/v2/${customerCode}/meters`, {
            headers: { Accept: 'application/json' },
            params: { _fields: 'id,name', _page: 1, _perPage: 10, _sort: 'name:asc', dataType: id },
        });
        return {
            data: response.data.map((item) => ({ id: item.id, name: item.name })),
            total: parseInt(response.headers['x-items-count']),
        };
    } catch (error) {
        throw new Error(error.response.data);
    }
}

import api from './api.config';

/**
 * Return the groups resource URL.
 *
 * @param {string} customerCode Customer code
 * @return {string} URL
 */
function groupsUrl(customerCode) {
    return `/timeseries/v2/${customerCode}/groups`;
}
/**
 * GET all groups
 *
 * @param {Object} params
 * @param {string} params.customerCode
 * @returns {Promise<Group[]>}
 */
export async function getGroups({ customerCode, fields }) {
    try {
        const response = await api.get(groupsUrl(customerCode), {
            params: { ...(fields && { _fields: fields.join() }) },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data ?? error);
    }
}

/**
 * Update a group
 * @param {Object} params
 * @param {User} params.group The updated group
 */
export async function updateGroup({ group, customerCode }) {
    try {
        const response = await api.put(`${groupsUrl(customerCode)}/${group.id}`, group);
        return response?.data;
    } catch (error) {
        throw new Error(error.response?.data ?? error);
    }
}

/**
 * Create a group.
 *
 * @param {Object} params
 * @param {String} params.customerCode the customer code
 * @param {Group} params.group the group to create
 * @returns {Promise<Group>} the created group
 */
export async function createGroup({ group, customerCode }) {
    try {
        const response = await api.post(groupsUrl(customerCode), group);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data ?? error);
    }
}
/**
 * Delete a group.
 *
 * @param {Object} params
 * @param {string} params.id The id of the group to delete
 */
export async function deleteGroup({ id, customerCode }) {
    try {
        await api.delete(`${groupsUrl(customerCode)}/${id}`);
    } catch (error) {
        throw new Error(error.response?.data ?? error);
    }
}

<template>
    <router-link class="SidebarItem flex items-center mb2" :class="{ active: isActive }" :to="item.to">
        <UiIcon
            v-tooltip:right="tooltip"
            class="inline-flex justify-center items-center"
            :icon-name="item.icon"
            :badge-color="badgeColor"
        />
        <span class="item-label flex-auto ml3">{{ $t(item.label) }}</span>
    </router-link>
</template>
<script>
import { UiIcon, UiTooltip as tooltip } from '@energiency/ui';

export const MAX_NOTIFICATIONS = 9;

export default {
    name: 'sidebar-item',
    components: {
        UiIcon,
    },
    directives: {
        tooltip,
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
        isActive: {
            type: Boolean,
            default: false,
        },
        isCollapsed: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        tooltip() {
            if (this.item.getTooltip && this.item.getTooltip()) {
                return this.isCollapsed
                    ? `${this.$t(this.item.label)} - ${this.$t(this.item.getTooltip())}`
                    : this.$t(this.item.getTooltip());
            }
            return this.isCollapsed ? this.$t(this.item.label) : undefined;
        },
        badgeColor() {
            if (!this.item.getBadgeColor) {
                return '';
            }
            return this.item.getBadgeColor();
        },
    },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
@import (reference) '~style/sidebar.less';
@import (reference) '~style/colors.less';
@import (reference) '~style/size.less';

.SidebarItem {
    position: relative;
    padding: 12px;
    overflow: hidden;
    white-space: nowrap;
    color: var(--grey-color-dark);
    border-radius: 0.375rem;
    text-decoration: none;
    flex-shrink: 0;
    transition: background 0.3s;
    .item-label {
        font-size: 1rem;
        line-height: 1;
        margin-top: 1px;
    }

    &:hover {
        cursor: pointer;
        background-color: @sidebar-bg-item-hover;
    }

    &.active {
        background-color: @sidebar-bg-item-active;
        .UiIcon {
            &.en-fw:before {
                width: 1em !important;
            }
        }
    }

    /deep/ .UiIcon {
        width: 20px !important;
        height: 20px;
        flex-shrink: 0;
        .svg-inline--fa {
            height: 20px;
        }
    }
}
</style>

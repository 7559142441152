<template>
    <UiThemeProvider class="w-100 h-100" :colors="{ main: mainColor }">
        <div>
            <router-view />
            <Notification />
        </div>
    </UiThemeProvider>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { NAMESPACE as NS_CLIPBOARD } from '@/store/modules/clipboard';
import { NAMESPACE as NS_SETTINGS } from '@/store/modules/settings';
import { UiThemeProvider, colors } from '@energiency/ui';
import Notification from '@/components/layout/Notification';

export default {
    components: {
        Notification,
        UiThemeProvider,
    },
    computed: {
        ...mapGetters(NS_SETTINGS, ['appearance']),
        mainColor() {
            return this.appearance?.mainColor ?? colors.primary;
        },
    },
    created() {
        window.addEventListener('storage', this.onStorageUpdate, false);
    },
    destroyed() {
        window.removeEventListener('storage', this.onStorageUpdate);
    },
    methods: {
        ...mapMutations(NS_CLIPBOARD, ['clear', 'copy']),
        onStorageUpdate(event) {
            if (event && event.key === 'clipboard') {
                if (event.newValue) {
                    this.copy(event.newValue);
                } else {
                    this.clear();
                }
            }
        },
    },
};
</script>

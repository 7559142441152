import { t } from 'i18next';
import { memoize, sortBy } from 'lodash';
import { checkRelation } from '@/api/perimeter.service';
import { resolveVariablesInFormula } from '@/utils/variable.utils';

const resolver = ({ value, customerCode, variables, meterId }) => {
    return JSON.stringify({
        value,
        customerCode,
        ...(variables && { variables: sortBy(variables, 'id') }),
        ...(meterId && { meterId }),
    });
};

export const formulaValidationCache = memoize(async ({ value, customerCode, variables, meterId, cancellable }) => {
    if (!value) {
        return {
            success: true,
            errorLocations: [],
        };
    }
    const relation = variables ? resolveVariablesInFormula(value, variables) : value;
    const check = await checkRelation({
        customerCode,
        meterId,
        relation,
        cancellable,
    });
    return {
        success: check?.success ?? false,
        errorLocations: !check?.success
            ? check?.errors.filter(({ location }) => location).map(({ location }) => location)
            : [],
    };
}, resolver);

export const getFormulaErrorMessage = ({ $pending, isValid, required }, errorLocations) => {
    if ($pending) {
        return null;
    }
    if (required !== undefined && !required) {
        return t('ERROR_REQUIRED_FIELD');
    }
    if (!isValid) {
        return errorLocations && errorLocations.length ? t('ERROR_FORMULA_SYNTAX') : t('ERROR_FORMULA_MISSING_METER');
    }
    return null;
};

export const formulaMeterIdRegex = /^\${[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}}$/;

import api from './api.config';

const AUTH_URL = '/timeseries/v2/auth';

/**
 * @typedef {Object} ChangePasswordCredentials
 * @property {string} username
 * @property {string} oldPassword
 * @property {string} customerCode
 */

/**
 * @typedef {Object} ChangePasswordToken
 * @property {string} token
 */

/**
 * @typedef {Object} Credentials
 * @property {string} login
 * @property {string} password
 */

/**
 * Change the current password by new one for first login
 * @param {Object} params
 * @param {ChangePasswordCredentials | ChangePasswordToken} params.credentials
 * @param {string} params.newPassword
 * @returns {Promise<{token:string}>}
 */
export async function changePassword({ credentials, newPassword }) {
    try {
        const response = await api.put(`${AUTH_URL}/change-password`, {
            ...credentials,
            newPassword,
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Login and retrieve token
 * @param {Object} params
 * @param {Credentials} params.credentials
 * @param {string} params.customerCode
 * @returns {Promise<{token:string}>}
 */
export async function login({ credentials: { login: username, password }, customerCode }) {
    try {
        const response = await api.post(`${AUTH_URL}`, {
            username,
            password,
            customerCode,
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Call API to logout (clear cookie)
 */
export async function logout() {
    try {
        await api.get(`${AUTH_URL}/logout`);
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * @param {Object} params
 * @param {string} params.email
 * @param {string} params.customerCode
 */
export async function resetPassword({ email, customerCode }) {
    try {
        const response = await api.post(`${AUTH_URL}/reset-password`, { email, customerCode });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Download SAML metadata
 *
 * @param {Object}   params
 * @param {string}   params.customerCode Customer code
 */
export async function downloadSAMLMetadata({ customerCode }) {
    try {
        const response = await api.get(`${AUTH_URL}/saml/metadata/?customer=${customerCode}`, {
            headers: {
                Accept: 'application/xml',
            },
            responseType: 'blob',
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

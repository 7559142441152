import api from './api.config';

/**
 * Return the categories resource URL.
 *
 * @param {string} customerCode Customer code
 * @return {string} URL
 */
function categoriesUrl(customerCode) {
    return `/timeseries/v2/${customerCode}/categories`;
}

/**
 * Get all categories
 *
 * @param {Object} params
 * @param {string} params.customerCode the customer code
 * @returns {Promise<Category[]>}
 */
export async function getCategories({ customerCode }) {
    try {
        const response = await api.get(categoriesUrl(customerCode), {
            headers: { Accept: 'application/json' },
        });
        return response?.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Update a category
 * @param {Object} params
 * @param {string} params.customerCode the customer code
 * @param {Category} params.category
 * @returns {Promise<Category>} params.Category The updated category
 */
export async function updateCategory({ customerCode, category }) {
    try {
        const response = await api.put(`${categoriesUrl(customerCode)}/${category.id}`, category, {
            headers: { Accept: 'application/json' },
        });
        return response?.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Create a category.
 *
 * @param {Object} params
 * @param {string} params.customerCode the customer code
 * @param {Category} params.category the category to create
 * @returns {Promise<Category>} the created category
 */
export async function createCategory({ customerCode, category }) {
    try {
        const response = await api.post(categoriesUrl(customerCode), category, {
            headers: { Accept: 'application/json' },
        });
        return response?.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Delete a category.
 *
 * @param {Object} params
 * @param {string} params.customerCode the customer code
 * @param {string} params.id The id of the category to delete
 */
export async function deleteCategory({ id, customerCode }) {
    try {
        await api.delete(`${categoriesUrl(customerCode)}/${id}`, { headers: { Accept: 'application/json' } });
    } catch (error) {
        throw new Error(error.response.data);
    }
}

import { viewModes as alertViewModes } from '@/components/alerts/composables';

// Alerts
const Alerts = () => import(/* webpackChunkName: "alerts" */ '@/pages/Alerts.vue');
const AlertsPanel = () => import(/* webpackChunkName: "alerts" */ '@/components/alerts/AlertsPanel.vue');
const AlertList = () => import(/* webpackChunkName: "alerts" */ '@/components/alerts/AlertList.vue');
const AlertHistoryList = () => import(/* webpackChunkName: "alerts" */ '@/components/alerts/AlertHistoryList.vue');
const AlertsCreationForm = () =>
    import(/* webpackChunkName: "alerts" */ '@/components/alerts/AlertsCreationForm/AlertsCreationForm.vue');

export default {
    name: 'customer.alerts',
    path: 'alerts',
    redirect: { name: 'alerts-active' },
    component: Alerts,
    meta: {
        title: 'ALERTS_TITLE',
    },
    children: [
        {
            name: 'alerts-active',
            path: '',
            components: {
                default: AlertList,
                layer: AlertsPanel,
            },
            meta: {
                title: 'ALERTS_TITLE',
                viewMode: alertViewModes.ONGOING,
            },
        },
        {
            name: 'alerts-history',
            path: 'history',
            components: {
                default: AlertHistoryList,
                layer: AlertsPanel,
            },
            meta: {
                title: 'ALERTS_TITLE',
                viewMode: alertViewModes.HISTORY,
            },
        },
        {
            name: 'alerts-configurations',
            path: 'configurations',
            components: {
                default: AlertList,
                layer: AlertsPanel,
            },
            meta: {
                title: 'ALERT_CONFIGURATIONS_TITLE',
                viewMode: alertViewModes.ALL,
            },
        },
        {
            name: 'alert-configuration-new',
            path: 'configurations/new',
            components: {
                fullPageLayer: AlertsCreationForm,
            },
            props: {
                fullPageLayer: (route) => ({ type: route.query.type }),
            },
            meta: {
                title: 'ALERT_CONFIGURATIONS_TITLE',
                viewMode: alertViewModes.ALL,
            },
        },
    ],
};

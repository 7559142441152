<template>
    <div class="Navbar flex items-center">
        <div class="logo">
            <img :src="logoUrl" alt="Energiency" @error="onError" />
        </div>
        <div class="actions flex items-center">
            <UiIconButton
                v-if="userGuideUrl"
                v-tooltip="$t('USER_GUIDE_TOOLTIP')"
                tag-name="a"
                class="mr2"
                icon-name="book-open-cover"
                color="white"
                appearance="flat"
                :href="userGuideUrl"
                target="_blank"
            />
            <HelpButton v-tooltip="$t('SUPPORT_TOOLTIP')" />
            <UserMenu />
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { NAMESPACE as NS_SETTINGS } from '@/store/modules/settings';
import { UiIconButton, UiTooltip as tooltip } from '@energiency/ui';
import HelpButton from './HelpButton';
import UserMenu from './UserMenu';

export default {
    components: {
        UiIconButton,
        UserMenu,
        HelpButton,
    },
    directives: {
        tooltip,
    },
    setup() {
        return {
            userGuideUrl: process.env.USER_GUIDE_URL,
        };
    },
    computed: {
        ...mapGetters(NS_SETTINGS, ['logoUrl']),
    },
    methods: {
        onError(event) {
            event.target.src = './images/logo-white.png';
        },
    },
};
</script>
<style lang="less" scoped>
@import (reference) '~style/navbar.less';
@import (reference) '~style/size.less';
@import (reference) '~style/colors.less';

.Navbar {
    min-height: @navbar-height;
    background-color: @navbar-bg-color;
    width: 100%;
    padding: 0;
    justify-content: space-between;
    .logo {
        margin-left: 1.5em;
    }
    img {
        max-width: 180px;
        max-height: 40px;
    }
    .actions {
        margin-right: 0.9em;
    }

    // custom css rules for pdf printing (elements hiding, size rules and colors)
    @media print {
        display: none;
    }
}
</style>

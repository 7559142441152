import axios from 'axios';
import api, { getAbortController } from './api.config';

const abortControllers = {};

/**
 * Return the statistics comparisons resource URL.
 *
 * @param {string} customerCode Customer code
 * @return {string} URL
 */
function statisticsUrl(customerCode) {
    return `/timeseries/v2/${customerCode}/timeseries/statistics`;
}

/**
 * Retrieve statistics data from the REST API.
 *
 * @param {Object} params
 * @param {string} params.customerCode Customer code
 * @param {Array}  params.dateRanges   List of date ranges (the first being the current range, the others
 *                                     being the comparison ranges)
 * @param {Array}  params.meters       List of meters (meterId, unit as symbol)
 * @param {Array}  params.filters      List of filters on customer's meters
 * @param {string} params.format       Format ("json" or "csv")
 * @return {Object|string} Statistics data
 */
export async function getStatistics({
    dashboardId,
    shareLinkId,
    customerCode,
    groupBy,
    statistics,
    dateRanges = [],
    meters = [],
    filters = [],
    format = 'json',
    lang = 'en',
    csvOptions = {},
    timezone,
    cancellable = false,
}) {
    if (dateRanges.length > 0 && meters.length > 0) {
        try {
            abortControllers.get = getAbortController(abortControllers.get);
            const response = await api.post(
                statisticsUrl(customerCode),
                {
                    dashboardId,
                    dateRanges,
                    meters,
                    filters,
                    csvOptions,
                    timezone,
                    groupBy,
                    statistics,
                },
                {
                    headers: {
                        Accept: format === 'csv' ? 'text/csv' : 'application/json',
                        'Accept-Language': lang,
                    },
                    ...(cancellable && { signal: abortControllers.get.signal }),
                    params: {
                        shareLinkId,
                    },
                },
            );

            return response.data;
        } catch (error) {
            if (!axios.isCancel(error)) {
                throw error.response;
            }
        }
    } else {
        return {};
    }
}

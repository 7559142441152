import { v4 as uuidv4 } from 'uuid';
import api from './api.config';

/**
 * @typedef {Object} Import
 * @property {string} firstDate Date of the first (oldest) data, as ISO-8601 string
 * @property {string} lastDate Date of the last (newest) data, as ISO-8601 string
 * @property {string} startDate Start date of the import, as ISO-8601 string
 * @property {string} endDate End date of the import, as ISO-8601 string
 * @property {string} name Name given to the import
 * @property {number} count Number of imported points
 * @property {Record<string,number>}meters Number of imported points by meter's id
 */

/**
 * Returns the documents resource URL.
 *
 * @param {string} customerCode Customer code
 * @return {string} URL
 */
function importsUrl(customerCode) {
    return `/timeseries/v2/${customerCode}/imports`;
}

/**
 * Create a import.
 *
 * @param {Object}  params
 * @param {string}  params.customerCode Customer code
 * @param {*}       params.file
 * @param {string}  params.timezone
 * @param {boolean} params.dryRun
 * @return {Object} The created import with an id
 */
export async function createImport({ customerCode, file, timezone, dryRun = false }) {
    const formData = new FormData();
    formData.append('file', file);
    try {
        const response = await api.post(importsUrl(customerCode), formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': file.type,
                'x-import-name': encodeURIComponent(file.name),
                'x-import-id': uuidv4(),
            },
            params: {
                timezone,
                ...(dryRun && { dryRun }),
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Retrieve imports from the REST API.
 *
 * @param {Object} params
 * @param {Date}   params.startDate Start date
 * @param {Date}   params.endDate   End date
 * @param {string} params.customerCode   Customer code
 * @return {Promise<Import[]>} Imports
 */
export async function getImports({ startDate, endDate, customerCode, sort, page, perPage, filters }) {
    try {
        const response = await api.get(importsUrl(customerCode), {
            headers: {
                Accept: 'application/json',
            },
            params: {
                startDate_gte: startDate.toISOString(),
                endDate_lt: endDate.toISOString(),
                ...filters,
                _sort: sort,
                _page: page,
                _perPage: perPage,
            },
        });
        return { data: response.data, total: parseInt(response.headers['x-items-count']) };
    } catch (error) {
        throw new Error(error?.response?.data ?? error.toString());
    }
}

/**
 * Retrieve imports from the REST API.
 *
 * @param {string} id
 * @param {string} params.customerCode   Customer code
 * @return {Promise<Import>} Import
 */
export async function getImport({ id, customerCode }) {
    try {
        const response = await api.get(`${importsUrl(customerCode)}/${id}`, {
            headers: {
                Accept: 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Retrieve the import file template according to customerCode
 *
 * @param {string} customerCode  Customer code
 * @return {Promise<Object[]>}   Import file template
 */
export async function getImportTemplate({ customerCode }) {
    try {
        const response = await api.get(`${importsUrl(customerCode)}/file-template`, {
            headers: {
                Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            responseType: 'blob',
        });
        return {
            file: response.data,
            filename: response.headers['content-disposition']?.split('filename=')[1].replace(/"/g, ''),
        };
    } catch (error) {
        throw new Error(error.response.data);
    }
}

import { Ability, AbilityBuilder } from '@casl/ability';
import { t } from 'i18next';
import Cookies from 'js-cookie';
import { isNil, keyBy } from 'lodash';
import { validate } from 'uuid';
import { login, logout } from '@/api/auth.service';
import {
    CREATE_USER_ALREADY_EXIST,
    UPDATE_USER_ALREADY_EXIST,
    createUser,
    deleteUser,
    getCurrentUser,
    getUsers,
    updateUser,
} from '@/api/users.service';
import { updateAppWithUserLocale } from '@/utils/languages.utils';
import { notify } from '@/helpers/notifications';
import { posthogClient } from '@/helpers/posthog';
import { initTag } from '@/helpers/sentry';
import dataModule from '@/store/reusable-modules/data-list.module';
import { addEdition } from '@/store/utils/edition.utils';
import { addFullscreen } from '@/store/utils/fullscreen.utils';
import {
    FETCH_CURRENT_USER,
    GO_TO_CREATE_USER,
    GO_TO_USER,
    GO_TO_USERS,
    LOGIN,
    LOGIN_SUCCESS,
    LOGOUT,
    UPDATE_USER,
} from './action-types';
import {
    FETCHING_CURRENT_USER,
    FETCH_CURRENT_USER_ERROR,
    FETCH_CURRENT_USER_SUCCESS,
    LOGGING,
    LOGIN_ERROR,
    LOGOUT_SUCCESS,
    SET_SHOULD_CHANGE_PASSWORD,
    UPDATE_USER_SUCCESS,
} from './mutation-types';

/**  @typedef { import('@/api/users.service.js').User } */
/** @typedef { import('@/api/auth.service.js').Credentials } */

/**
 * @typedef {Object} UsersState
 * @property {boolean}      isLogging                   Indicates whether the user is logging
 * @property {boolean}      shouldChangePassword        Indicates whether the user should change password
 * @property {boolean}      isFetchingCurrentUser       Indicates whether the current user are being fetched
 * @property {boolean}      hasFetchCurrentUserError    Indicates whether the current user fetch has failed
 * @property {User}         currentUser
 * @property {Object}       ability
 */

/** @type {UsersState} */
const _state = {
    isLogging: false,
    shouldChangePassword: false,
    isFetchingCurrentUser: false,
    hasFetchCurrentUserError: false,
    currentUser: null,
    ability: new AbilityBuilder(Ability).build(),
};

export const mutations = {
    /**
     * @param {UsersState} state
     * @param {boolean} isLogging
     */
    [LOGGING](state, isLogging) {
        state.isLogging = isLogging;
    },
    /**
     * @param {UsersState} state
     */
    [LOGIN_ERROR](state) {
        state.isLogging = false;
    },
    [SET_SHOULD_CHANGE_PASSWORD](state, value) {
        state.shouldChangePassword = value;
    },
    [LOGOUT_SUCCESS](state) {
        Cookies.remove('isAuthenticated');
        Cookies.remove('customerCode');
        state.currentUser = null;
    },
    /**
     * @param {UsersState} state
     */
    [FETCHING_CURRENT_USER](state) {
        state.isFetchingCurrentUser = true;
    },
    /**
     * @param {UsersState}             state
     * @param {User}                user
     */
    [FETCH_CURRENT_USER_SUCCESS](state, { user }) {
        state.currentUser = user;
        state.isFetchingCurrentUser = false;
        state.hasFetchCurrentUserError = false;
        updateAppWithUserLocale(user);

        // Update abilities
        state.ability.update(user.rules || []);
    },
    /**
     * @param {UsersState} state
     */
    [FETCH_CURRENT_USER_ERROR](state) {
        state.isFetchingCurrentUser = false;
        state.hasFetchCurrentUserError = true;
    },
    /**
     * @param {UsersState} state
     * @param {User} item
     */
    [UPDATE_USER_SUCCESS](state, item) {
        if (item.id === state.currentUser.id) {
            if (item.language !== state.currentUser.language) {
                updateAppWithUserLocale(item);
            }
            state.currentUser = item;
        }
        const index = (state.users.items ?? []).findIndex(({ id }) => id === item.id);
        if (index >= 0) {
            state.users.items.splice(index, 1, { ...item });
        }
    },
};

export const getters = {
    /**
     * Indicates whether the user are being creating
     * @param {UsersState}  state
     * @returns {boolean}   `true` if user are being creating
     */
    isCreatingUser: (state) => state.users.pending.create,
    /**
     * Indicates whether the users are being fetching
     * @param {UsersState}  state
     * @returns {boolean}   `true` if user are being fetching
     */
    isFetchingUsers: (state) => state.users.pending.items,
    /**
     * Returns the current user firstName and lastName. If user is not found it returns the current user ID.
     *
     * @param {UsersState} state
     * @returns {string}
     */
    userFullName: (_, _getters) => (id) => {
        if (!validate(id)) {
            return '';
        }
        const user = _getters.usersById[id];
        return user ? `${user.firstName} ${user.lastName}` : t('UNKNOWN_USER');
    },
    /**
     * Returns the users group by id
     *
     * @param {UsersState} state
     * @returns {Object}
     */
    usersById: (state) => {
        return keyBy(state.users.items, 'id');
    },
    /**
     * Returns the current user ID.
     *
     * @param {UsersState} state
     * @returns {string}
     */
    currentUserId: (state) => (state.currentUser ? state.currentUser.id : undefined),
    /**
     * Return the ID corresponding to the route `:id`.
     *
     * @param {UsersState} state
     * @param {UserGetters} _getters
     * @param {RootState} rootState
     * @return {string} ID of the user
     */
    selectedUserId: (state, _getters, rootState) => {
        return rootState.route?.params?.id;
    },

    /**
     * Return the user corresponding to the route `:id`. Otherwise `undefined`.
     *
     * @param {UsersState} state
     * @param {UserGetters} _getters
     * @return {User}
     */
    selectedUser: (state, _getters) => {
        const userId = _getters.selectedUserId;
        return state.users.items && userId !== null ? _getters.usersById[userId] : undefined;
    },
};

export const actions = {
    /**
     * Authenticate user and save token
     * @param {Object} context
     * @param {{credentials: Credentials, customerCode: string}} payload
     */
    async [LOGIN]({ commit, dispatch }, { credentials, customerCode, router, redirectRoute, stayConnected }) {
        commit(LOGGING, true);
        try {
            await login({ customerCode, credentials });
            dispatch(LOGIN_SUCCESS, { customerCode, router, redirectRoute, stayConnected });
        } catch (error) {
            commit(LOGIN_ERROR);
            if (error.message === 'ERR_CHANGE_PASSWORD_MESSAGE') {
                commit(SET_SHOULD_CHANGE_PASSWORD, true);
            } else {
                notify({ type: 'error', text: t('LOGIN_FAILURE_TEXT') });
            }
        }
    },
    /**
     * @param {Object} context
     * @param {boolean} reload
     */
    async [LOGOUT]({ commit }, { router }) {
        await logout();
        commit(LOGOUT_SUCCESS);
        router
            .push({
                name: 'signin',
                params: {
                    customerCode: router.currentRoute?.params?.customerCode,
                },
            })
            .catch();
    },
    /**
     * @param {Object} context
     * @param {Object} payload
     * @returns {Router}
     */
    [LOGIN_SUCCESS]({ commit }, { customerCode, router, redirectRoute, stayConnected }) {
        commit(LOGGING, false);
        Cookies.set('customerCode', customerCode, stayConnected ? { expires: 7 } : {});
        Cookies.set('isAuthenticated', true, stayConnected ? { expires: 7 } : {});
        if (redirectRoute) {
            return router
                .push({
                    ...redirectRoute,
                    params: {
                        ...redirectRoute.params,
                        customerCode,
                    },
                })
                .catch();
        }
        return router
            .push({
                name: 'customer',
                params: {
                    customerCode,
                },
            })
            .catch();
    },
    /**
     * Fetch user.
     *
     * @param {Object} context
     */
    async [FETCH_CURRENT_USER]({ commit, state, rootGetters }) {
        if (isNil(state.currentUser)) {
            commit(FETCHING_CURRENT_USER);
            try {
                const user = await getCurrentUser({ customerCode: rootGetters.customerCode });
                // init Sentry tag
                initTag(user, rootGetters.customerCode);

                if (posthogClient) {
                    posthogClient.identify(user.id, {
                        email: user.email,
                        name: `${user.firstName} ${user.lastName}`,
                        customerCode: rootGetters.customerCode,
                        role: user.role,
                        login: user.login,
                    });
                }

                commit(FETCH_CURRENT_USER_SUCCESS, { user });
            } catch (error) {
                commit(FETCH_CURRENT_USER_ERROR);
            }
        }
    },

    /**
     * Navigate to users list.
     *
     * @param {Object} context
     * @param {Object} payload
     */
    [GO_TO_USERS](context, { router }) {
        router.push({ name: 'customer.users' });
    },

    /**
     * Navigate to an user.
     *
     * @param {Object} context
     * @param {Object} payload
     * @param {string} payload.id
     */
    [GO_TO_USER](context, { id, router }) {
        router.push({
            name: 'user-infos',
            params: { id },
            query: { ...router.currentRoute.query },
        });
    },
    /**
     * Navigate to user creation form
     */
    [GO_TO_CREATE_USER](context, { router }) {
        router.push({ name: 'users-new' });
    },

    /**
     * Update an user
     * @param {User} user
     */
    async [UPDATE_USER]({ commit, rootGetters }, user) {
        try {
            const updatedUser = await updateUser({
                customerCode: rootGetters.customerCode,
                user,
            });
            commit(UPDATE_USER_SUCCESS, updatedUser);
            notify({
                type: 'success',
                text: t('USER_UPDATE_SUCCESS'),
            });
        } catch (error) {
            const errorMessage =
                error === UPDATE_USER_ALREADY_EXIST ? t('USER_ALREADY_EXIST_ERROR') : t('USER_UPDATE_ERROR');
            notify({
                type: 'error',
                text: errorMessage,
            });
            throw error;
        }
    },
};

export const NAMESPACE = 'users';

export const ROLES = { READ: 'read', WRITE: 'write' };

export default addFullscreen(
    addEdition(
        {
            namespaced: true,
            state: _state,
            actions,
            mutations,
            getters,
            modules: {
                users: dataModule({
                    namespaced: false,
                    getItems({ rootGetters }) {
                        return getUsers({ customerCode: rootGetters.customerCode });
                    },
                    async createItem({ dispatch, rootGetters }, { item, router }) {
                        try {
                            const createdUser = await createUser({
                                customerCode: rootGetters.customerCode,
                                user: item,
                            });
                            notify({ type: 'success', text: t('CREATE_USER_SUCCESS') });
                            dispatch(GO_TO_USER, { id: createdUser.id, router });
                            return createdUser;
                        } catch (error) {
                            const errorMessage =
                                error === CREATE_USER_ALREADY_EXIST
                                    ? t('USER_ALREADY_EXIST_ERROR')
                                    : t('CREATE_USER_ERROR');
                            notify({ type: 'error', text: errorMessage });
                            throw error;
                        }
                    },
                    async deleteItem({ dispatch, rootGetters }, { item, router }) {
                        try {
                            await deleteUser({
                                customerCode: rootGetters.customerCode,
                                id: item.id,
                            });
                            notify({ type: 'success', text: t('DELETE_USER_SUCCESS') });
                            dispatch(GO_TO_USERS, { router });
                        } catch (error) {
                            notify({ type: 'error', text: t('DELETE_USER_ERROR') });
                            throw error;
                        }
                    },
                }),
            },
        },
        {
            saveFunction: actions[UPDATE_USER],
        },
    ),
);

<template>
    <div class="MainPage w-100 h-100 flex flex-column">
        <Navbar />
        <div class="w-100 flex flex-auto">
            <Sidebar />
            <div class="page-content flex-auto">
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '../navbar/Navbar';
import Sidebar from '../sidebar/Sidebar';

export default {
    components: {
        Navbar,
        Sidebar,
    },
};
</script>

import _ from 'lodash';

/**
 * Return an object of getters to retrieve `startDate` and `endDate`
 * from the route.
 */
export const dateRangeGetters = {
    /**
     * Start of the time range.
     *
     * @return {string} Start of the time range as ISO-8601 string, including timezone designator.
     */
    start: (state, _getters, rootState) => _.get(rootState.route, 'query.startDate'),
    /**
     * End of the time range.
     *
     * @return {string} End of the time range as ISO-8601 string, including timezone designator.
     */
    end: (state, _getters, rootState) => _.get(rootState.route, 'query.endDate'),
    /**
     * Start of the time range.
     *
     * @return {Date}
     */
    startDate: (state, _getters) => (_getters.start ? new Date(_getters.start) : undefined),
    /**
     * End of the time range.
     *
     * @return {Date}
     */
    endDate: (state, _getters) => (_getters.end ? new Date(_getters.end) : undefined),
};

export const dateRangeActionTypes = {
    RESET_ZOOM: 'RESET_ZOOM',
};

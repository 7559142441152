import axios from 'axios';
import Cookies from 'js-cookie';

function goToSignin() {
    const customerCode = Cookies.get('customerCode');
    Cookies.remove('isAuthenticated');
    if (!window.location.pathname.endsWith('/signin')) {
        window.location.href = `${customerCode}/signin`;
        Cookies.remove('customerCode');
    }
}

const api = axios.create({
    baseURL: '/api',
    headers: {
        'Access-Control-Allow-Origin': '*',
    },
});

/**
 * Response Interceptor that:
 *  - reject the promise for every non valid status codes
 */
api.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (axios.isCancel(error)) {
            throw error;
        }

        const { status } = error.response;

        if (status === 401 && Cookies.get('customerCode')) {
            goToSignin();
            throw error;
        }
        throw error;
    },
);

/**
 * Response Interceptor that:
 *  - add BOM for csv in utf-8
 **/
api.interceptors.response.use((response) => {
    if (response.headers['content-type'] === 'text/csv; charset=utf-8') {
        response.data = `\ufeff${response.data}`;
    }
    return response;
});

/**
 * Return an AbortController.
 *
 * @param {Object} previousAbortController If given, cancel a previous controller
 * @returns {Object} New AbortController
 * @see https://axios-http.com/docs/cancellation
 */
export function getAbortController(previousAbortController) {
    if (previousAbortController) {
        previousAbortController.abort();
    }
    return new AbortController();
}

export default api;

import api from './api.config';

/**
 * @typedef {Object} Documents
 * @property {Object} documents
 */

/**
 * Returns the documents resource URL.
 *
 * @param {string} customerCode Customer code
 * @return {string} URL
 */
function documentsUrl(customerCode) {
    return `/timeseries/v2/${customerCode}/documents`;
}

/**
 * Uploads a document.
 *
 * @param {Object}   params
 * @param {string}   params.customerCode Customer code
 * @param {Settings} params.subjectType The subject Type
 * @param {Settings} params.subjectId The subject Id
 * @param {Settings} params.document The document to add
 * @return {Settings} Uploaded document
 */
export async function uploadDocument({ customerCode, subjectType, subjectId, parentId, document }) {
    const formData = new FormData();
    formData.append('document', document);
    formData.append('name', document.name);
    if (parentId) {
        formData.append('parentId', parentId);
    }
    if (subjectType) {
        formData.append('subjectType', subjectType);
    }
    if (subjectId) {
        formData.append('subjectId', subjectId);
    }
    try {
        const response = await api.post(documentsUrl(customerCode), formData, {
            headers: {
                Accept: 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Gets documents list.
 *
 * @param {Object}   params
 * @param {string}   params.customerCode Customer code
 * @param {Object}   params.filters List of filters
 * @return {Settings} List of document
 */
export async function getDocuments({ customerCode, filters }) {
    const url = documentsUrl(customerCode);
    try {
        const response = await api.get(url, {
            headers: {
                Accept: 'application/json',
            },
            params: { ...filters },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
}

/**
 * Rename a document
 *
 * @param {Object} params
 * @param {string} customerCode Customer code
 * @param {string} documentId   The document Id
 */
export async function updateDocument({ customerCode, document }) {
    try {
        const response = await api.put(
            `${documentsUrl(customerCode)}/${document.id}`,
            {
                name: document.name,
                parentId: document.parentId,
            },
            {
                headers: {
                    Accept: 'application/json',
                },
            },
        );
        return response.data;
    } catch (error) {
        throw new Error(error.response);
    }
}

/**
 * Deletes a document
 *
 * @param {Object} params
 * @param {string} customerCode Customer code
 * @param {string} documentId       The document Id
 */
export async function deleteDocument({ customerCode, id }) {
    try {
        await api.delete(
            `${documentsUrl(customerCode)}/${id}`,
            {},
            {
                headers: {
                    Accept: 'application/json',
                },
            },
        );
    } catch (error) {
        throw new Error(error.response);
    }
}

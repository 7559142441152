import { hasRessourceAccess } from '@/router/navigation-guards.utils';

// Users
const Users = () => import(/* webpackChunkName: "users" */ '@/pages/Users.vue');
const User = () => import(/* webpackChunkName: "users" */ '@/components/users/User.vue');
const CreateUser = () => import(/* webpackChunkName: "users" */ '@/components/users/CreateUser');
const UsersList = () => import(/* webpackChunkName: "users" */ '@/components/users/UsersList');

const Group = () => import(/* webpackChunkName: "users" */ '@/components/groups/Group.vue');
const GroupList = () => import(/* webpackChunkName: "users" */ '@/components/groups/GroupList.vue');
const CreateGroup = () => import(/* webpackChunkName: "users" */ '@/components/groups/CreateGroup.vue');

export default {
    name: 'customer.users',
    path: 'users',
    redirect: { name: 'users-list' },
    component: Users,
    children: [
        {
            name: 'users-new',
            path: 'new',
            components: {
                fullPageLayer: CreateUser,
            },
        },
        {
            name: 'users-groups-new',
            path: 'groups/new',
            components: {
                fullPageLayer: CreateGroup,
            },
        },
        {
            name: 'users-groups',
            path: 'groups',
            components: {
                default: GroupList,
                layer: Group,
            },
            children: [
                {
                    name: 'users-group',
                    path: ':id',
                    redirect: { name: 'users-group-infos' },
                    children: [
                        {
                            name: 'users-group-infos',
                            path: 'infos',
                        },
                    ],
                },
            ],
        },
        {
            name: 'users-list',
            path: '',
            components: {
                default: UsersList,
                layer: User,
            },
            children: [
                {
                    name: 'user',
                    path: ':id',
                    redirect: { name: 'user-infos' },
                    children: [
                        {
                            name: 'user-infos',
                            path: 'infos',
                        },
                    ],
                },
            ],
        },
    ],
    async beforeEnter(to, from, next) {
        if (await hasRessourceAccess('update', 'User', { isMe: false, role: 'reader' })) {
            next();
        } else {
            next({ name: 'customer', params: { customerCode: to.params.customerCode } });
        }
    },
};

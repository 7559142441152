import Vue from 'vue';

/**
 * This method displays notifications
 */
export function notify({ type = 'success', text, duration, id = null }) {
    if (!duration) {
        duration = type === 'success' ? 1500 : 3000;
    }
    Vue.notify({
        type,
        text,
        duration,
        id,
    });
}

/**
 * This method closes a notifications
 * @param {string} id
 */
export function close(id) {
    Vue.notify.close(id);
}

import { isLibraryTemplate } from '@/utils/dashboard.utils';
import { variableToPerimeter } from '@/utils/variable.utils';
import { NAMESPACE as NS_METERS } from '@/store/modules/meters';

export const mutationTypes = {
    SET_WIDGET_FULLSCREEN: 'SET_WIDGET_FULLSCREEN',
};

export const actionTypes = {
    UPDATE_GRID_WIDGET: 'UPDATE_GRID_WIDGET',
};

export default function ({ getGrid, updateGridWidget }) {
    return {
        state() {
            return {
                fullscreenWidgetId: null,
            };
        },
        getters: {
            /**
             * Returns the grid
             *
             * @param {Object} context
             * @returns {string}
             */
            grid: (...context) => getGrid(...context),
            /**
             * Return the variables
             *
             * @param {GridState} state
             * @param {GridGetters} _getters
             * @returns {object} Variable list
             */
            variables: (state, _getters) => {
                return _getters.grid.variables ?? [];
            },
            /**
             * Return the meters
             *
             * @param {GridState} state
             * @param {GridGetters} _getters
             * @returns {array}
             */
            meters: (state, _getters, rootState, rootGetters) => {
                const meterRefs = Object.values(_getters.grid.meters ?? {}).map((meter) => ({
                    ...meter,
                    visible: false,
                }));
                if (isLibraryTemplate(_getters.grid)) {
                    return meterRefs;
                }
                const meters = rootGetters[`${NS_METERS}/meters`] ?? [];
                return [...meterRefs, ...meters];
            },
            /**
             * Return the variables as perimeter
             *
             * @param {GridState} state
             * @param {any} _getters
             * @returns {object} Perimeter list
             */
            variablesAsPerimeter: (state, _getters) => {
                return variableToPerimeter(_getters.variables);
            },
            /**
             * Return the variable of the given id. Otherwise `undefined`
             *
             * @param {GridState} state
             * @param {GridGetters} _getters
             * @returns {object} Variable object
             */
            getVariableById: (state, _getters) => (variableId) => {
                return _getters.variables.find((v) => v.id === variableId);
            },
            /**
             * Return the variables of the given types
             *
             * @param {GridState} state
             * @param {GridGetters} _getters
             * @returns {array} Variables array
             */
            getVariablesByTypes: (state, _getters) => (variableTypes) => {
                const types = Array.isArray(variableTypes) ? variableTypes : [variableTypes];
                return _getters.variables.filter((v) => types.includes(v.type));
            },
            /**
             * Return the meter by its id
             * @param {GridState} state
             * @param {GridGetters} _getters
             * @param {RootState} rootState
             * @param {RootGetters} rootGetters
             * @returns {string} Meter object
             */
            getMeterById: (state, _getters, rootState, rootGetters) => (meterId) => {
                return _getters.grid.meters?.[meterId] || rootGetters[`${NS_METERS}/getMeterById`](meterId); // Fallback to meter from perimeter when editing a widget (so meter is not yet contained in the dashboard)
            },
            /**
             * Return the perimeter tree with variables
             * @param {GridState} state
             * @param {GridGetters} _getters
             * @param {RootState} rootState
             * @param {RootGetters} rootGetters
             * @returns {string} Meter object
             */
            perimeterTreeWithVariables: (state, _getters, rootState, rootGetters) => {
                if (isLibraryTemplate(_getters.grid)) {
                    return {
                        children: [variableToPerimeter(_getters.variables)],
                    };
                }
                const perimeterTree = rootGetters[`${NS_METERS}/perimeterTree`];
                return {
                    ...perimeterTree,
                    children: [variableToPerimeter(_getters.variables), ...(perimeterTree.children || [])],
                };
            },
        },
        mutations: {
            /**
             * Set the Fullscreen Widget Id
             *
             * @param {GridState} state
             * @param {string} fullscreenWidgetId
             */
            [mutationTypes.SET_WIDGET_FULLSCREEN](state, fullscreenWidgetId) {
                state.fullscreenWidgetId = fullscreenWidgetId;
            },
        },
        actions: {
            async [actionTypes.UPDATE_GRID_WIDGET](context, { widget, refresh = false }) {
                await updateGridWidget(context, { widget, refresh });
            },
        },
    };
}

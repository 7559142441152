import { t } from 'i18next';
import { groupBy } from 'lodash';
import { createTag, deleteTag, getTags, updateTag } from '@/api/tags.service';
import { notify } from '@/helpers/notifications';
import dataModule from '@/store/reusable-modules/data-list.module';
import { addEdition } from '@/store/utils/edition.utils';
import { GO_TO_CREATE_TAG, GO_TO_TAG, GO_TO_TAGS, UPDATE_TAG } from './action-types';
import { DELETE_TAGS_BY_CATEGORY, UPDATE_TAG_SUCCESS } from './mutation_types';

export const NAMESPACE = 'tags';

const _state = {};

const getters = {
    /**
     * Return the tags
     *
     * @param {TagState} state
     * @returns {array}} The tags list
     */
    tags: (state) => {
        return state?.tags?.items ?? [];
    },

    isFetchingTags: (state) => {
        return state?.tags?.pending?.items ?? false;
    },

    /**
     * Return the ID corresponding to the route `:id`.
     *
     * @param {TagState} state
     * @param {TagGetters} _getters
     * @param {RootState} rootState
     * @return {string} ID of the tag
     */
    selectedTagId: (state, _getters, rootState) => {
        return rootState.route?.params?.id;
    },

    /**
     * Return the tag corresponding to the route `:id`. Otherwise `undefined`.
     *
     * @param {TagState} state
     * @param {TagGetters} _getters
     * @return {Tag}
     */
    selectedTag: (state, _getters) => {
        const tagId = _getters.selectedTagId;
        return _getters.tags && tagId !== null ? _getters.tags.find(({ id }) => tagId === `${id}`) : undefined;
    },

    /**
     * Return the tag organised by categoryId
     *
     * @param {TagState} state
     * @param {TagGetters} _getters
     * @return {Object}
     */
    tagsByCategoryId: (state, _getters) => {
        return groupBy(_getters.tags, 'categoryId');
    },
};

const mutations = {
    /**
     *
     * @param {*} state
     * @param {*} tag
     */
    [UPDATE_TAG_SUCCESS](state, tag) {
        const index = state.tags.items.findIndex(({ id }) => id === tag.id);
        if (index >= 0) {
            state.tags.items.splice(index, 1, tag);
        }
    },

    [DELETE_TAGS_BY_CATEGORY](state, { id }) {
        state.tags.items = state.tags.items.filter(({ categoryId }) => categoryId !== id);
    },
};

export const actions = {
    /**
     * Navigate to tag creation form
     * @param {Object} context
     */
    [GO_TO_CREATE_TAG](context, { router }) {
        router.push({ name: 'tags-new' });
    },

    /**
     * Navigate to the tags list
     * @param {*} context
     */
    [GO_TO_TAGS](context, { router }) {
        router.push({
            name: 'tags',
            query: router.currentRoute.query,
        });
    },

    /**
     * Navigate to a tag
     * @param {*} context
     */
    [GO_TO_TAG](context, { id, router }) {
        router.push({
            name: 'tag-infos',
            params: { id },
            query: router.currentRoute.query,
        });
    },

    /**
     * Update tag
     *  @param {Object} context
     */
    async [UPDATE_TAG]({ commit, rootGetters }, tag) {
        try {
            await updateTag({
                customerCode: rootGetters.customerCode,
                tag,
            });
            notify({
                type: 'success',
                text: t('UPDATE_TAG_SUCCESS'),
            });
            commit(UPDATE_TAG_SUCCESS, tag);
        } catch (error) {
            notify({
                type: 'error',
                text: t('UPDATE_TAG_ERROR'),
            });
            throw error;
        }
    },
};

export default addEdition(
    {
        namespaced: true,
        state: _state,
        getters,
        mutations,
        actions,
        modules: {
            tags: dataModule({
                namespaced: false,
                async getItems({ rootGetters }) {
                    const { data: items } = await getTags({
                        customerCode: rootGetters.customerCode,
                    });
                    return items;
                },
                async createItem({ dispatch, rootGetters }, { item, router }) {
                    try {
                        const tag = await createTag({
                            customerCode: rootGetters.customerCode,
                            tag: item,
                        });

                        dispatch(`${NAMESPACE}/${GO_TO_TAG}`, { id: item.id, router }, { root: true });
                        notify({ type: 'success', text: t('CREATE_TAG_SUCCESS') });
                        return tag;
                    } catch (error) {
                        notify({ type: 'error', text: t('CREATE_TAG_ERROR') });
                        throw error;
                    }
                },
                async deleteItem({ dispatch, rootGetters }, { item, router }) {
                    try {
                        await deleteTag({
                            customerCode: rootGetters.customerCode,
                            id: item.id,
                        });
                        notify({ type: 'success', text: t('DELETE_TAG_SUCCESS') });
                        dispatch(GO_TO_TAGS, { router });
                    } catch (error) {
                        notify({ type: 'error', text: t('DELETE_TAG_ERROR') });
                        throw error;
                    }
                },
            }),
        },
    },
    {
        saveFunction: actions[UPDATE_TAG],
    },
);

import { useCookies } from '@vueuse/integrations/useCookies';
import { computed } from 'vue';
import { useRoute } from 'vue-router/composables';
import { getShareLinkParams } from '@/utils/sharing.utils';

/**
 * Returns a composable to get the current customer code.
 *
 * @returns Ref to customer code
 *
 * @usage
 *
 * ```
 * const customerCode = useCustomerCode();
 * ```
 */
export const useCustomerCode = () => {
    const route = useRoute();
    const { get: getCookie } = useCookies(['customerCode']);

    return computed(
        () => getShareLinkParams(route)?.customerCode ?? route.params.customerCode ?? getCookie('customerCode'),
    );
};

const Reports = () => import(/* webpackChunkName: "reports" */ '@/pages/Reports.vue');
const Report = () => import(/* webpackChunkName: "reports" */ '@/pages/Report.vue');
export default {
    name: 'customer.reports',
    path: 'reports',
    redirect: { name: 'customer.reports-list' },
    component: {
        render: (h) => h('router-view'),
    },
    children: [
        {
            name: 'customer.reports-list',
            path: '',
            component: Reports,
            meta: {
                title: 'REPORTS_TITLE',
            },
        },
        {
            name: 'customer.report',
            path: ':id',
            component: Report,
            meta: {
                title: 'REPORTS_TITLE',
            },
        },
        {
            name: 'customer.new-reports',
            path: 'new-reports',
            redirect: {
                name: 'customer.reports-list',
            },
            children: [
                {
                    path: ':id',
                    redirect: { name: 'customer.report' },
                },
            ],
        },
    ],
};
